import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import mailAnime from "../../assets/mail.gif"
import styles from "./notVerified.module.css"
import { hashMail } from '../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, postRequest } from '../../Service/httpService';
import { API_URL } from '../../Config/constant';
import { toast } from 'react-toastify';
import { userData as saveUserData } from '../../store/reducers/routeReducer';

const NotVerified = () => {
    const [resendTimer, setResendTimer] = useState("0");
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state.routeReducer);

    const sendMail = async () => {
        setResendTimer(59); 

        getRequest({ url: API_URL.RESEND_VERIFICATION_MAIL })
            .then((res) => {
                toast.success(res.data.message);
            })
            .catch((err) => {
                toast.error(err.message);
            })
        let timer = 59;
        while (timer > 0) {
            await new Promise(r => setTimeout(r, 1000));
            setResendTimer(state => state - 1);
            timer--;
        }
    }

    const updateUserData = () => {
        getRequest({ url: API_URL.GET_USER_DETAILS })
            .then((rep) => {
                dispatch(saveUserData(rep.data.data));
            })
            .catch((error) => { });
    }


    useEffect(() => {
        if ((resendTimer==="0") || (userData && !userData.isVerified && resendTimer != 0 && resendTimer % 10 === 0)) {
            userData && !userData.isVerified && updateUserData();
        }
    }, [resendTimer])

    return (
        <Modal
            show={userData && !userData.isVerified}
            dialogClassName="modal-90w"
            centered
            aria-labelledby="example-custom-modal-styling-title"
        >

            <Modal.Body>
                <div className={styles["container"]}>
                    <div className={styles["container-item"]}>
                        <img src={mailAnime} />
                    </div>
                    <div className={styles["container-item"]}>
                        <h2>Verify your email</h2>
                    </div>
                    <div className={styles["container-item"]}>
                        <p>Almost there! We've  sent a verification email to {userData && hashMail(userData.email)}</p>
                        <p>You need to verify your email address to login to your account.</p>
                    </div>
                    <div className={styles["container-item"]}>
                        <button className={styles['btn']} disabled={resendTimer > 0} onClick={() => { sendMail() }} >Resend email</button>
                        {
                            resendTimer >0 && <p className={styles["timer"]} > 00:{(resendTimer < 10 ? '0' : '') + resendTimer}</p>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NotVerified