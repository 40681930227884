import React, { useState } from "react";
import { API_URL } from "../Config/constant";
import { postRequest } from "../Service/httpService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import showPwdImg from "../assets/hide-password.svg";
import hidePwdImg from "../assets/show-password.svg";
import { isLogin, userData } from "../store/reducers/routeReducer";
import { toCapitalCase } from "../Shared Component/toCapitalCase";
import { InputGroup } from "react-bootstrap";
import userIcon from "../assets/icons/user6Icon.svg";
import passIcon from "../assets/icons/passIcon.svg";
import { useLocation, useNavigate } from "react-router";

import { isLoading as setIsLoading } from "../store/reducers/routeReducer";

const LoginComponent = (props) => {
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showEmailError, setshowEmailError] = useState();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  async function signInHandler(event) {
    event.preventDefault();
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)) {
      setshowEmailError(true);
      // alert("enter valid email");
      return;
    } else {
      setshowEmailError(false);
    }

    dispatch(setIsLoading(true));

    await postRequest({
      url: API_URL.LOGIN_API,
      data: { email: userEmail, password: userPassword },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(userData(response.data.userData));
          dispatch(isLogin(true));
          toast.success(toCapitalCase(response.data.message));
          // toast(
          //   <div className="bg-red-500">
          //     👍 {" " + toCapitalCase(response.data.message)}
          //   </div>
          // );
          navigate("/");
          // props.submitHandlerTopNav();
        } else {
          toast.error(toCapitalCase(response.data.message));
          navigate("/");

          // props.errorHandlerTopNav(response.data.message);
        }
      })
      .catch((e) => toast.error("Wrong Credentials"));

    dispatch(setIsLoading(false));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Form
      onSubmit={signInHandler}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <InputGroup className="mb-3">
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          type="number"
          placeholder="Price"
          onChange={perPersionPriceSet}
          value={perPersionPrice}
          required
        />
      </InputGroup> */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <InputGroup>
          <InputGroup.Text
            style={{ border: "solid 2px #F5F5F5", background: "transparent" }}
          >
            <img style={{ width: "16px" }} src={userIcon} />
          </InputGroup.Text>
          <Form.Control
            style={{
              border: "solid 2px #F5F5F5",
              borderLeft: "none",
              fontWeight: "500",
              fontSize: "15px",
            }}
            type="email"
            placeholder="Email Address"
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </InputGroup>
        {showEmailError ? (
          <span
            style={{
              color: "red",
              fontSize: "12px",
            }}
            className="err"
          >
            *Enter valid email
          </span>
        ) : null}
      </Form.Group>
      <Form.Group
        style={{
          position: "relative",
        }}
        className="mb-3"
        controlId="formBasicPassword"
      >
        <InputGroup>
          <InputGroup.Text
            style={{ border: "solid 2px #F5F5F5", background: "transparent" }}
          >
            <img style={{ width: "16px" }} src={passIcon} />
          </InputGroup.Text>
          <Form.Control
            style={{
              border: "solid 2px #F5F5F5",
              borderLeft: "none",
              fontWeight: "500",
              fontSize: "15px",
            }}
            placeholder="Enter Password"
            type={isRevealPwd ? "text" : "password"}
            onChange={(e) => setUserPassword(e.target.value)}
          />
          <img
            style={{
              width: "20px",
              position: "absolute",
              right: "7px",
              top: "9px",
              cursor: "pointer",
              zIndex: "99",
            }}
            title={isRevealPwd ? "Hide password" : "Show password"}
            src={isRevealPwd ? hidePwdImg : showPwdImg}
            onClick={() => setIsRevealPwd((prevState) => !prevState)}
          />
        </InputGroup>
      </Form.Group>
      <Button
        disabled={!userEmail || !userPassword}
        className="submit"
        type="submit"
        variant="primary"
      >
        Log In
      </Button>
      <div>
        <a href="/forgetpassword" className="forget-password-button">
          Forget Password
        </a>
      </div>
    </Form>
  );
};

export default LoginComponent;
