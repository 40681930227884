import React, { useEffect, useState } from "react";
import styles from "./tripFilter.module.css";
import searchIcon from "../../assets/icons/searchIcon.svg";
import checked from "../../assets/icons/checkbox-checked-green.svg";
import unChecked from "../../assets/icons/checkbox-unchecked-icon.svg";
import { artTypes } from "../../dummyData/artTypes";
import closedFilter from "../../assets/icons/closed-filter-trips.svg";
import openedFilter from "../../assets/icons/opened-filter-trips.svg";
import { getRequest } from "../../Service/httpService";
import { API_URL } from "../../Config/constant";
import { manageLongText } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { tripFilter as setTripsFilter } from "../../store/reducers/routeReducer";

const TripFilters = () => {
  const { tripFilter } = useSelector((state) => state.routeReducer);
  const [showFilters, setShowFilters] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [minPrice, setMinPrice] = useState(
    tripFilter ? tripFilter.minPrice : 0
  );
  const [maxPrice, setMaxPrice] = useState(
    tripFilter ? tripFilter.maxPrice : 100
  );
  const [departureDate, setDepartureDate] = useState(
    tripFilter ? tripFilter.departureDate : "DD/MM/YYYY"
  );
  const dispatch = useDispatch();

  const getProvinces = () => {
    getRequest({
      url: `${API_URL.PROVINCES_TRIPS}?search=${search}&type=2`,
    })
      .then((res) => {
        let data = res.data.data?.filter((e) => e.trips_count > 0);

        setProvinces(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    getProvinces();
  }, [search]);

  const apply = () => {
    const filter = {
      departureDate,
      provinces: selectedProvinces,
      minPrice,
      maxPrice,
    };
    dispatch(setTripsFilter(filter));
  };
  const reset = () => {
    setSelectedProvinces([]);
    setDepartureDate(null);
    setMinPrice(0);
    setMaxPrice(null);

    const filter = {
      departureDate: null,
      provinces: [],
      minPrice: null,
      maxPrice: null,
    };

    dispatch(setTripsFilter(filter));
  };

  return (
    <div className={styles["trip-filters"]}>
      <div className={styles["header"]}>
        <h3>Filters</h3>
        <img
          src={showFilters ? openedFilter : closedFilter}
          alt=""
          onClick={() => setShowFilters(!showFilters)}
        />
      </div>

      {showFilters && (
        <>
          <PriceFilter
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
          />
          <PlaceFilter
            search={search}
            setSearch={setSearch}
            provinces={provinces}
            selectedProvinces={selectedProvinces}
            setSelectedProvinces={setSelectedProvinces}
          />
          <DateFilter
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
          />
          <Buttons apply={apply} reset={reset} />
        </>
      )}
    </div>
  );
};

function PriceFilter({ minPrice, maxPrice, setMinPrice, setMaxPrice }) {
  const manageMinPrice = (e) => {
    setMinPrice(
      e.target.value == "" ? "" : Math.min(Math.abs(e.target.value), maxPrice)
    );
  };

  const manageMaxPrice = (e) => {
    setMaxPrice(
      e.target.value == "" ? "" : Math.max(minPrice, Math.abs(e.target.value))
    );
  };

  return (
    <div className={styles["price-filter"]}>
      <div className={styles["label"]}>
        <span>Price</span>
      </div>
      <div className={styles["filter"]}>
        <div className={styles["filter-item"]}>
          <div className={styles["label"]}>Min</div>
          <div className={styles["filter-input"]}>
            <span className={styles["input-box"]}>
              <input type="number" value={minPrice} onChange={manageMinPrice} />
            </span>
            <span className={styles["currency-box"]}>
              <input type="text" value="USD" disabled />
            </span>
          </div>
        </div>

        <div className={styles["to"]}>to</div>
        <div className={styles["filter-item"]}>
          <div className={styles["label"]}>Max</div>
          <div className={styles["filter-input"]}>
            <span className={styles["input-box"]}>
              <input
                type="number"
                value={maxPrice === null ? "" : maxPrice}
                onChange={manageMaxPrice}
              />
            </span>
            <span className={styles["currency-box"]}>
              <input type="text" value="USD" disabled />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function PlaceFilter(props) {
  const {
    search,
    setSearch,
    provinces,
    selectedProvinces,
    setSelectedProvinces,
  } = props;

  const [allProvinceView, setAllProvinceView] = useState(false);

  const handleCheck = (province) => {
    if (selectedProvinces.includes(province.province)) {
      const updatedCheckes = selectedProvinces.filter(
        (item) => item !== province.province
      );
      setSelectedProvinces(updatedCheckes);
    } else {
      const updatedCheckes = [...selectedProvinces, province.province];
      setSelectedProvinces(updatedCheckes);
    }
  };
  return (
    <div className="type-filter">
      <div className={styles["label"]}>
        <span>Places</span>
      </div>

      <div className={styles["search"]}>
        <img src={searchIcon} className={styles["search-icon"]} />
        <span className={styles["vertical-line"]} />
        <input
          type="text"
          placeholder="Search Type"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className={styles["suggestions"]}>
        {provinces?.map(
          (province, index) =>
            (allProvinceView ? true : index < 5) && (
              <div key={index} className={styles["suggestion"]}>
                <div
                  className={styles["left"]}
                  onClick={() => {
                    handleCheck(province);
                  }}
                >
                  <img
                    src={
                      selectedProvinces.includes(province.province)
                        ? checked
                        : unChecked
                    }
                  />
                  <span
                    className={
                      selectedProvinces.includes(province.province)
                        ? styles["check-label-active"]
                        : styles["check-label"]
                    }
                  >
                    {manageLongText(province.province, 20)}
                  </span>
                </div>
                <span
                  className={
                    selectedProvinces.includes(province.province)
                      ? styles["count-active"]
                      : styles["count"]
                  }
                >
                  {province.trips_count}
                </span>
              </div>
            )
        )}
        {provinces?.length > 5 && !allProvinceView && (
          <div
            onClick={() => setAllProvinceView(true)}
            className={styles["more"]}
          >
            +{provinces?.length - 5} more
          </div>
        )}
        {allProvinceView && (
          <div
            onClick={() => setAllProvinceView(false)}
            className={styles["more"]}
          >
            Hide
          </div>
        )}
      </div>
    </div>
  );
}

function DateFilter({ departureDate, setDepartureDate }) {
  return (
    <div className={styles["date-filter-container"]}>
      <span className={styles["label"]}>Departure Date</span>
      <div className={styles["date-filter"]}>
        <input
          type="date"
          value={departureDate == null ? "DD/MM/YYYY" : departureDate}
          onChange={(e) => {
            setDepartureDate(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

function Buttons({ apply, reset }) {
  return (
    <div className={styles["buttons"]}>
      <button
        className={styles["reset"]}
        onClick={async () => {
          await reset();
        }}
      >
        Reset
      </button>
      <button className={styles["apply"]} onClick={apply}>
        Apply
      </button>
    </div>
  );
}

export default TripFilters;
