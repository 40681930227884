import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../assets/icons/searchIcon.svg";
import styles from "./searchbar.module.css";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";

const Searchbar = ({ setShowRest, setShowNavOptions }) => {
  const [vw, setVw] = useState(1000);
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState("");
  const formRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  const timer = useRef();

  //   setting query
  useEffect(() => {
    if (location?.pathname?.includes("search")) {
      let path = location.pathname;
      const index = path.lastIndexOf("/");
      const query = decodeURI(path?.substring(index + 1));

      setQuery(query);
    }
  }, [location.pathname]);

  const handleResize = () => {
    setVw(window.innerWidth);
  };

  useEffect(() => {
    if (vw === 1000) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleExpanded = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", toggleExpanded);

    return () => {
      document.body.removeEventListener("click", toggleExpanded);
    };
  }, []);

  useEffect(() => {
    if (vw < 700) {
      setShowRest(!isExpanded);
      setShowNavOptions(!isExpanded);
    } else {
      setShowRest(true);
      setShowNavOptions(true);
    }
  }, [isExpanded]);

  //   const handleSearch = (e) => {
  //     if (e.key == "Enter" && query.length > 0) {
  //       setQuery("");
  //       navigate("/search/" + query);
  //     }
  //   };

  const handleSearch = (e) => {
    navigate("/search/" + e);
  };

  return (
    <div className={styles[vw < 700 && isExpanded ? "wrap-expanded" : "wrap"]}>
      <form
        ref={formRef}
        className={
          styles[
            vw < 700
              ? isExpanded
                ? "search-small-expanded"
                : "search-small"
              : "search"
          ]
        }
        onClick={toggleExpanded}
      >
        {vw < 700 && !isExpanded ? (
          <button
            className="w-5"
            onClick={(e) => {
              e.preventDefault();
            }}
            //   type="submit"
          >
            <img className="w-full" src={searchIcon} alt="" />
            <div className="verticalLine" />
          </button>
        ) : (
          <button
            className={styles["searchButton"]}
            onClick={(e) => {
              e.preventDefault();
            }}
            //   type="submit"
          >
            <img src={searchIcon} alt="" />
            <div className={styles["verticalLine"]} />
          </button>
        )}
        <input
          type="text"
          className={styles["searchTerm"]}
          placeholder={"Search in MemoCD"}
          onChange={(e) => {
            setQuery(e.target.value);

            if (timer.current) {
              clearTimeout(timer.current);
            }

            timer.current = setTimeout(() => {
              handleSearch(e.target.value);
              clearTimeout(timer.current);
            }, 800);
          }}
          value={query}
          //   onKeyDown={(e) => {
          //     handleSearch(e);
          //   }}
        ></input>
      </form>
    </div>
  );
};

export default Searchbar;
