/**
 * @author Ravi Rose (23/11/2022)
 */
import axios from "axios";
import { BACKEND_URL } from "../Config/constant";
const AxiosInstance = axios.create({
  timeout: 120000,
  baseURL: `${BACKEND_URL}/api/`,
});

export const getRequest = async ({ url, param }) => {
  const token = localStorage.getItem("authorization");

  try {
    return await AxiosInstance({
      method: "get",
      url: url,
      ...(token && {
        headers: {
          Authorization: `${localStorage.getItem("authorization")}`,
        },
      }),
    });
  } catch (error) {
    // if (error.response.status === 401) {
    //   localStorage.removeItem("authorization");
    // }

    let data = error.response && error.response.data;
    return { error: true, message: error.message, data: data };
  }
};


export const postRequest = async ({ url, data }) => {
  const token = localStorage.getItem("authorization");
  try {
    return await AxiosInstance({
      method: "post",
      url: url,
      data,
      ...(token && {
        headers: {
          Authorization: `${localStorage.getItem("authorization")}`,
        },
      }),
    });
  } catch (error) {
    // if (error.response.status === 401) {
    //   localStorage.removeItem("authorization");
    // }

    let data = error.response && error.response.data;
    return { error: true, message: error.message, data: data };
  }
};

export const putRequest = async ({ url, data }) => {
  const token = localStorage.getItem("authorization");
  try {
    return await AxiosInstance({
      method: "put",
      url: url,
      data,
      ...(token && {
        headers: {
          Authorization: `${localStorage.getItem("authorization")}`,
        },
      }),
    });
  } catch (error) {
    // if (error.response.status === 401) {
    //   localStorage.removeItem("authorization");
    // }

    let data = error.response && error.response.data;
    return { error: true, message: error.message, data: data };
  }
};
export const deleteRequest = async ({ url, data }) => {
  const token = localStorage.getItem("authorization");
  try {
    return await AxiosInstance({
      method: "delete",
      url: url,
      data,
      ...(token && {
        headers: {
          Authorization: `${localStorage.getItem("authorization")}`,
        },
      }),
    });
  } catch (error) {
    // if (error.response.status === 401) {
    //   localStorage.removeItem("authorization");
    // }

    let data = error.response && error.response.data;
    return { error: true, message: error.message, data: data };
  }
};