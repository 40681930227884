export const formatDate = (inputDate) => {
  // Create a new Date object with the input date
  var date = new Date(inputDate);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // this is a comment

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  // Format the date string
  var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

  return formattedDate;
};

export const capitalizeText = (text) => {
  // Convert the whole string to lowercase
  var lowercasedText = text.toLowerCase();

  // Split the string into an array of words
  var words = lowercasedText.split(" ");

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  var capitalizedText = capitalizedWords.join(" ");

  return capitalizedText;
};

export const dateFormatOne = (date) => {
  const createdDate = date.split("T")[0];
  const day = createdDate.split("-")[2];
  const month = createdDate.split("-")[1];
  const year = createdDate.split("-")[0];
  return `${day}/${month}/${year}`;
};

export const formatDateRange = (departureDate, returnDate) => {
  const startDate = new Date(departureDate);
  const endDate = new Date(returnDate);

  // Check if the inputs are valid Date objects
  if (isNaN(startDate) || isNaN(endDate)) {
    throw new Error("Invalid date input");
  }

  // Format the day and month for the start and end dates
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const startMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    startDate
  );

  // Check if the range spans multiple months
  const isMultiMonth = startDate.getMonth() !== endDate.getMonth();

  // Construct the formatted date string
  let formattedDate = `${startDay}-${endDay} ${startMonth}`;

  // Add the end month if it's different from the start month
  if (isMultiMonth) {
    formattedDate += ` - ${new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(endDate)}`;
  }

  // Add the year
  formattedDate += ` ${startDate.getFullYear()}`;

  return formattedDate;
};

export const formatDateTime = (inputDate) => {
  // Create a new Date object with the input date
  var date = new Date(inputDate);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Format the date string
  var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

  // Format the time string
  var formattedTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    " " +
    period;

  // Combine date and time
  var dateTimeString = formattedDate + " at " + formattedTime;

  return dateTimeString;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional: Add smooth scrolling animation
  });
};

export const getDashboardRoute = (user) => {
  switch (user.role) {
    case "admin":
      return "/admin/dashboard";
    case "partner":
      return "/partner/dashboard";
    case "moderator":
      return "/moderator/dashboard";
    default:
      return "/not-found-404";
  }
};

export const hashMail = (mail) => {
  const firstHalf = mail.split("@")[0];
  const secondHalf = mail.split("@")[1];

  const email = firstHalf[0] + "****" + "@" + secondHalf;

  return email;
};

export const formDataToObject = (formData) => {
  const object = {};
  formData.forEach((value, key) => {
    if (object.hasOwnProperty(key)) {
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key].push(value);
    } else {
      object[key] = value;
    }
  });
  return object;
};

export const expectedDelivery = (days, newDate) => {
  if (!newDate) newDate = new Date();
  const currentDate = new Date(newDate);
  const futureDate = new Date(currentDate); // Create a new date object based on the current date
  futureDate.setDate(currentDate.getDate() + days); // Add N days to the new date object

  // Define months array to map month number to month name
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and year from the future date
  const day = futureDate.getDate();
  const month = months[futureDate.getMonth()];
  const year = futureDate.getFullYear();

  // Format the date as "26 Jun, 2023" and return
  return `${day} ${month}, ${year}`;
};

export const validateTripForm = (formData) => {
  const title = formData.get("title");
  const destination = formData.get("destination");
  const price = formData.get("price");
  const startDate = formData.get("startDate");
  const endDate = formData.get("endDate");
  const availableTickets = formData.get("availableTickets");
  const vehicleType1 = formData.get("vehicles[0][type]");
  const vehicleModel1 = formData.get("vehicles[0][model]");
  const departurePlace = formData.get("departurePlace");
  const landmark = formData.get("landmark");
  const departureDate = formData.get("departureDate");
  const returnDate = formData.get("returnDate");
  const departureTime = formData.get("departureTime");
  const returnTime = formData.get("returnTime");

  // Mandatory field checks
  const mandatoryFields = [
    title,
    destination,
    price,
    startDate,
    endDate,
    availableTickets,
    vehicleType1,
    vehicleModel1,
    departurePlace,
    landmark,
    departureDate,
    returnDate,
    departureTime,
    returnTime,
  ];

  for (const field of mandatoryFields) {
    if (!field) {
      return "Mandatory fields are required.";
    }
  }

  // Title length check
  if (title.length < 5 || title.length > 50) {
    return "Title should be between 5 and 50 characters.";
  }

  // StartDate and EndDate check
  if (new Date(startDate) > new Date(endDate)) {
    return "StartDate should be less than or equal to EndDate.";
  }

  // Departure and Return date/time check
  const departureDateTime = new Date(`${departureDate} ${departureTime}`);
  const returnDateTime = new Date(`${returnDate} ${returnTime}`);
  if (departureDateTime > returnDateTime) {
    return "Departure date/time should be before return date/time.";
  }

  // No errors, return null
  return null;
};

export const formatTime = (time) => {
  let hour = parseInt(time.split(":")[0]);
  let minute = time.split(":")[1];
  return `${hour % 12}:${minute} ${hour > 12 ? "PM" : "AM"}`;
};

export const valueToLabel = (value) => {
  const arr = value.split("_");
  let label = "";
  for (const item of arr) {
    label = label + item + " ";
  }
  // label.capitalizeText();
  return label;
};

export const isValidLink = (link) => {
  const urlRegex = /^https:\/\/.*\.(com|in|org|net)(\/.*)?$/i;

  if (!link.trim()) {
    // If the link is empty, it is considered valid
    return true;
  }

  return urlRegex.test(link);
};

export const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const manageLongText = (text, n) => {
  if (!text) return "";
  if (!n) n = 16;
  if (text.length > n) {
    return `${text.slice(0, n)}...`;
  }
  return text;
};

export const isActive = (url, tab) => {
  switch (tab) {
    case "trends":
      return url === "/" || url.split("/")[1] === "userPosts";
    case "trips":
      return url.split("/")[1] === "trips" || url.split("/")[1] === "places";
    case "arts":
      return url.split("/")[1] === "artwork";
    default:
      return (
        url.split("/")[1] === "partner" ||
        url.split("/")[1] === "admin" ||
        url.split("/")[1] === "moderator"
      );
  }
};

export const whichRegion = (state) => {
  const regions = {
    "kongo-central": "west",
    "maï-ndombe": "west",
    kwilu: "west",
    kwango: "west",
    equateur: "west",
    kinshasa: "west",
    "sud-ubangi": "north",
    "nord-ubangi": "north",
    mongala: "north",
    "bas-uele": "north",
    "haut-uele": "north",
    tshopo: "east",
    ituri: "east",
    "nord-kivu": "east",
    maniema: "east",
    "sud-kivu": "east",
    lualaba: "south",
    "haut-lomami": "south",
    tanganyika: "south",
    "haut-katanga": "south",
    lomami: "south",
    "kasaï-oriental": "center",
    tshuapa: "center",
    sankuru: "center",
    "kasaï-central": "center",
    kasaï: "center",
  };
  return regions[state];
};

export const countComments = (comments) => {
  let count = comments.length;

  comments.map((el) => {
    if (el.replies?.[0]?.author?.name) {
      count += el.replies.length;
    }
  });

  return count;
};

export const getLast12Months = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const result = [];
  for (let i = currentMonth; i >= 0; i--) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );

    const monthObject = {
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: `${date.getMonth() + 1}-${date.getFullYear()}`,
    };

    result.unshift(monthObject);
  }

  for (let i = 11; i > currentMonth; i--) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );

    const monthObject = {
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: `${date.getMonth() + 1}-${date.getFullYear()}`,
    };

    result.unshift(monthObject);
  }

  return result;
};

export const isValidImgUrl = (testUrl) => {
  let url;
  try {
    url = new URL(testUrl);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};
