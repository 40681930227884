import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import LoginSignUpTabs from "../Elements/loginSignupModaltabs";
import frame61 from "../assets/images/Frame 61.svg";
import closeBtn from "../assets/images/closeButton.svg";
import frame81 from "../assets/images/Frame81.svg";
import googleIcon from "../assets/icons/googleAuthIcon.svg";
import facebookIcon from "../assets/icons/facebookAuthIcon.svg";
import { useNavigate } from "react-router-dom";
import updatedLogo from "../assets/images/newLogo.png";
import { BACKEND_URL } from "../Config/constant";
import loginPoster from "../assets/posters/home.jpg";

const LoginPopUpModal = ({
  lgShow,
  setLgShow,
  selectedTab,
  setSelectedTab,
}) => {
  const { isLogin } = useSelector((state) => state.routeReducer);
  const queryParams = new URLSearchParams(window.location.search);
  const [user, setUser] = useState();
  const modal = queryParams.get("modal");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (modal === "login") {
      setLgShow(true);
      setSelectedTab("Login-Tab");
    }
    if (modal === "signup") {
      setLgShow(true);
      setSelectedTab("SignUp-Tab");
    }
  }, [modal]);

  const handleHide = () => {
    setLgShow(false);
    if (modal === "login" || modal === "signup") navigate(-1);
  };

  function handleGoogleLogin() {
    const url = `${BACKEND_URL}/auth/google`;
    window.open(url, "_self");
  }

  const handleFacebookLogin = () => {
    window.open(`${BACKEND_URL}/auth/facebook`, "_self");
    return;
  };

  return (
    <Modal
      size="lg"
      show={!isLogin && lgShow}
      onHide={() => handleHide()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="overflow-y-hidden">
        <div className="login-pop">
          <div className="login-popup-box">
            <div className="image-box">
              <img src={loginPoster} alt="" />
            </div>
            <div className="login-popup-rightbox">
              <div className="login-popup-logo mt-4">
                <img className="group3" src={updatedLogo} alt="" />
                <img
                  className="closeBtn"
                  src={closeBtn}
                  onClick={() => {
                    handleHide();
                  }}
                />
              </div>
              <div className="login-box-title">
                <h3>Log in to MemoCD</h3>
                {/* <p>Log in to Memo</p> */}
              </div>
              <div className="login-popup-button-tabs">
                {/* <button>Login</button>
                                    <button>SignUp</button> */}
                <LoginSignUpTabs
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </div>
              {/* <div style={{
                  display:"flex",
                  marginTop:"20px",
                  width:"100%",
                  alignItems:"center",
                  columnGap:"2rem",
                }}
                className="google_facebook_tab">
                <img style={{
                  width:"24px",
                  height:"24px",
                  cursor:"pointer",
                }}
                src="./images/google.svg" alt="" />
                <img 
                style={{
                  width:"24px",
                  height:"24px",
                  cursor:"pointer",
                }}
                src="./images/facebook.svg" alt="" />
                </div> */}
              <div className="socialAuthorization">
                <div className="frame81">
                  <img src={frame81} alt="" />
                </div>
                <div className="socialAuthorizationIcons">
                  {/* <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                    <Google setUser={setUser} />
                  </GoogleOAuthProvider> */}

                  <img src={googleIcon} onClick={handleGoogleLogin} alt="" />

                  <img
                    src={facebookIcon}
                    onClick={handleFacebookLogin}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginPopUpModal;
