import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoginComponent from "./logInComponent";
import SignUpComponent from "./signUpComponent";

export default function LoginSignUpTabs(props) {
  return (
    <Tabs
      defaultActiveKey={props.selectedTab}
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab  eventKey="Login-Tab" title="Log in">
        <LoginComponent
          setSelectedTab={props.setSelectedTab}
          // submitHandlerTopNav={() => props.submitHandlerTopNav("login")}
          // errorHandlerTopNav={(message) => props.errorHandlerTopNav(message)}
        />
      </Tab>
      <Tab eventKey="SignUp-Tab" title="Sign up">
        <SignUpComponent
          setSelectedTab={props.setSelectedTab}
          // submitHandlerTopNav={() => props.submitHandlerTopNav("signup")}
          // errorHandlerTopNav={(message) => props.errorHandlerTopNav(message)}
        />
      </Tab>
    </Tabs>
  );
}
