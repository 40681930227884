import { createSlice } from "@reduxjs/toolkit";
import logger from "redux-logger";
//import { isPermission } from "../../Utils/permissions";
let initialState = {};
try {
  initialState = {
    middleWidth: 506,
    userData: null,
    isLogin: false,
    isLoading: false,
    placesState: 1,
    partnerState: 1,
    artworkState: 1,
    chatModalOpen: false,
    chatModalState: "conversations",
    chatPartner: null,
    tripFilter: {
      minPrice: 0,
      maxPrice: null,
      provinces: [],
    },
    artworkFilter: {
      minPrice: 0,
      maxPrice: 100,
      types: [],
      provinces: [],
      unique: false,
      onOrder: false,
      newOnly: false,
    },
    cart: [],
    showTripCheckout: false,
    selectedTrip: {},
    showArtworkCheckout: false,
    selectedArtwork: {},
    editProfile: false,
  };
} catch (error) {
  initialState = {
    userData: null,
    isLogin: false,
    isLoading: false,
    chatModalOpen: false,
    chatModalState: "conversations",
    chatPartner: null,
    cart: [],
    tripFilter: {
      minPrice: 0,
      maxPrice: null,
      provinces: []
    },
    artworkFilter: {
      minPrice: 0,
      maxPrice: 100,
      types: [],
      provinces: [],
      unique: false,
      onOrder: false,
      newOnly: false,
    },
    showTripCheckout: false,
    selectedTrip: {},
    showArtworkCheckout: false,
    selectedArtwork: {},
    editProfile: false,
  };
}

export const routeReducer = createSlice({
  name: "route_reducer",
  initialState,
  reducers: {
    userData: (state, action) => {
      if (action.payload) {
        localStorage.setItem("userData", JSON.stringify(action.payload));
        localStorage.setItem("authorization", action.payload.token);
      }
      state.userData = action.payload;
    },
    isLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    logout: (state, action) => {
      localStorage.removeItem("userData");
      localStorage.removeItem("authorization");
      state.userData = null;
      state.isLogin = false;
      state.placesState = 1;
      state.partnerState = 1;
      state.artworkState = 1;
    },
    search: (state, action) => {
      state.search = action.payload;
    },
    placesState: (state, action) => {
      state.placesState = action.payload;
    },
    partnerState: (state, action) => {
      state.partnerState = action.payload;
    },
    artworkState: (state, action) => {
      state.artworkState = action.payload;
    },
    middleBoxWidth: (state, action) => {
      state.middleWidth = action.payload;
    },
    chatModalOpen: (state, action) => {
      state.chatModalOpen = action.payload;
    },
    chatModalState: (state, action) => {
      state.chatModalState = action.payload;
    },
    chatPartner: (state, action) => {
      state.chatPartner = action.payload;
    },
    cart: (state, action) => {
      state.cart = [...action.payload];
    },
    editProfile: (state, action) => {
      state.editProfile = action.payload;
    },
    tripFilter: (state, action) => {
      state.tripFilter = action.payload;
    },
    artworkFilter: (state, action) => {
      state.artworkFilter = action.payload;
    },
    showTripCheckout: (state, action) => {
      state.showTripCheckout = action.payload;
    },
    selectedTrip: (state, action) => {
      state.selectedTrip = action.payload;
    },
    showArtworkCheckout: (state, action) => {
      state.showArtworkCheckout = action.payload;
    },
    selectedArtwork: (state, action) => {
      state.selectedArtwork = action.payload;
    },
  },
});

export const {
  userData,
  isLogin,
  logout,
  search,
  placesState,
  partnerState,
  artworkState,
  middleBoxWidth,
  chatModalOpen,
  chatModalState,
  chatPartner,
  isLoading,
  cart,
  tripFilter,
  artworkFilter,
  showTripCheckout,
  showArtworkCheckout,
  selectedTrip,
  selectedArtwork,
  editProfile
} = routeReducer.actions;

export default routeReducer.reducer;
