import React, { useEffect, useState } from "react";
import closeIcon from "../../../assets/icons/close-icon.svg";
import styles from "../chat-box.module.css";
import { getRequest } from "../../../Service/httpService";
import defaultProfile from "../../../assets/images/default_profile_Pic.png";
import { API_URL } from "../../../Config/constant";
import { useDispatch } from "react-redux";
import {
  chatModalState as setChatModalState,
  chatPartner as setChatPartner,
} from "../../../store/reducers/routeReducer";

function ConversationPage({ closeModal, conversations, user }) {
  return (
    <div className={styles["chat-window"]}>
      <div className={styles["modalHeader"]}>
        <div className="text-white font-semibold">My Conversations</div>
        <div className={styles["cur-pointer"]}>
          <img
            onClick={() => {
              closeModal();
            }}
            style={{ height: "15px", width: "15px" }}
            src={closeIcon}
            alt=""
          />
        </div>
      </div>
      <div className={styles["modal-body"]}>
        <div className=" px-6">
          <div className=" w-full text-sm py-3 uppercase ">
            {conversations.length} Conversations
          </div>

          <div className="flex flex-col gap-2">
            {conversations &&
              conversations.length > 0 &&
              conversations?.map((convo) => (
                <Conversation key={convo._id} convo={convo} user={user} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Conversation({ convo, user }) {
  const [friend, setFriend] = useState(null);
  const dispatch = useDispatch();
  const friendId = convo.members.find((item) => item !== user._id);

  const getfriend = () => {
    getRequest({ url: API_URL.GET_ANOTHER_USER_DATA + friendId })
      .then((res) => {
        setFriend(res.data.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    getfriend();
  }, []);

  return (
    <div
      className="flex items-center cursor-pointer justify-between bg-bgGray px-2 py-2 rounded-full "
      onClick={() => {
        {
          dispatch(setChatModalState("chat"));
          dispatch(setChatPartner(friend));
        }
      }}
    >
      <div className="flex h-full items-center gap-x-4">
        {convo.unreadMessagesCount > 0 && (
          <span>
            {convo.unreadMessagesCount > 9 ? "9+" : convo.unreadMessagesCount}
          </span>
        )}
        <div className="w-12 h-12 rounded-full overflow-hidden">
          {friend && (
            <img
              className="w-full h-full"
              src={friend.avatarImg ? friend.avatarImg : defaultProfile}
              alt=""
            />
          )}
        </div>
        <div className="">
          <div className="flex items-center gap-x-2">
            <span>
              {friend && (
                <span className="text-base font-semibold">{friend.name}</span>
              )}
            </span>
            {/* <span className="text-xs">message count</span> */}
          </div>
          <div className="text-xs text-textgray">Last Activity: Today</div>
        </div>
      </div>

      {convo.unreadMessagesCount > 0 && (
        <div className="bg-red-500/30 text-xs text-red-300 font-semibold p-2 px-4 rounded-full">
          {convo.unreadMessagesCount > 9 ? "9+" : convo.unreadMessagesCount}{" "}
          Unread
        </div>
      )}
    </div>
  );
}

export default ConversationPage;
