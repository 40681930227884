import React from "react";
import "./loader.css";
// import loader from "../../assets/gifs/loader.gif"
import loader from "../../assets/gifs/loader2.gif";
// import loader from "../../assets/gifs/loader.svg";
const Loader = () => {
  return (
    // <div className="loaderContainer">
    <div className="w-screen h-screen fixed top-0 left-0 z-[999999999999] flex justify-center items-center bg-black/80">
      <div className="loaderBox">
        <div className="load-cont">
          <div className="load-orange"></div>
          <div className="load-green"></div>
        </div>
        {/* <img src={loader} /> */}
      </div>
    </div>
  );
};

export default Loader;
