export const API_URL = {
  LOGIN_API: "login",
  SIGN_UP: "signup",
  RESEND_VERIFICATION_MAIL: "verification/resend",
  Forget_Password: "forgotPassword",
  Change_Password: "changepassword",
  Update_profile: "updateProfile",
  Upload_Avatar: "uploadAvatar",
  GET_USER_DETAILS: "getUserData",
  GET_USER_PROFILE: "user",
  GET_ANOTHER_USER_DATA: "chat/getuserdata/",
  // Social_Media_Post_Datas: "socialMediaPostDatas",
  Social_Media_Post_Datas: "trendsData",
  GLOBAL_SEARCH: "find",
  SPECIFIC_USER_POSTS: "searchTrendByUserName",
  REPORT_: "social/deletepostbyreport",
  Social_Media_Analytics_Trends: "socialAnalyticsTrends",
  CREATE_PLACE: "/places/create",
  CREATE_PARTNER: "/partner/register/request",
  PLACES: "/places",
  Create_Partner: "createPartner",
  GET_ALL_Partner: "getAllPartner",
  VERIFY_PARTNER_DETAILS: "approvePartner",
  GET_SUGGEST_PLACE_INFO: "getSuggestPlaceInfo",
  VERIFY_PLACE_DETAILS: "verifyOrReject",
  SELL_ART_WORK: "sellArtWork",
  GET_ALL_APPROVED_ARTWORKS: "getAllArtWorkInfo",
  ART_WORK_BY_ID: "artWorksById",
  ART_SUGGESTION: "art/suggestion",
  GET_LIST_APPROVED: "getListApproved",
  APPROVE_ARTWORK: "approvedArtWork",
  GET_ALL_PLACES: "getAllPlaces",
  GET_HASHTAG: "getAllHashtags",
  SET_HASHTAG: "setHashtags",
  REMOVE_HASHTAG: "removeHashtags",
  SEARCH_DATA_URL: "searchDetailsInSearchBar",
  GOOGLE_LOGIN_URL: "login/google",
  ARTWORK_BY_ID_URL: "artWorksById/",
  ARTWORK_BY_TYPE_URL: "artWorksByType/",
  CREATE_A_TRIP: "addTrip",
  GET_ALL_TRIPS: "trips",
  TRIPS_IN_A_PLACE: "/trips-in-place",
  GET_SINGLE_TRIP: "trip",
  SUGGEST_PARTNERS: "suggestPartners",
  TAG_A_PARTNER: "/tag",
  GET_A_POST: "post/",
  GET_NOTIFICATIONS: "notifications/",
  GET_ALL_RECOMMENDATIONS: "recommendations",
  GET_MY_TRIPS: "mytrips/",
  GET_CART: "/cart",
  ADD_TO_CART: "/cart/add",
  DELETE_FROM_CART: "/cart/delete",
  CHECKOUT_URL: "pay",
  EMPTY_CART: "emptyCart",
  GET_CONVERSATIONS: "chat/",
  GET_MESSAGES: "chat/find/",
  SEND_MESSAGE: "chat/send",
  BECOME_PARTNER: "becomepartner",
  GALLERY: "gallery",
  UPLOAD_ARTWORK: "artwork/upload",
  GALLERIES_DATA: "/artwork/galleries",
  NEARBY_TRIPS: "/trips/nearby",
  SEARCH_DESTINATIONS: "/trips/destination",
  TRENDS_HASHTAGS: "/list-hashtags",
  PARTNERS_IN_MY_REGION: "/artwork/partner/my-region",
  GET_PLACES: "/places",
  COMMENTS: "/comment",
  LIKE_COMMENT: "/comment/like",
  DISLIKE_COMMENT: "/comment/dislike",
  REPLY_COMMENT: "/comment/reply",
  ARTWORK_TYPES: "/artwork/types",
  ARTWORK_PROVINCES: "/artwork/provinces",
  ARTWORKS: "/artworks",
  PROVINCES_TRIPS: "/trips/provinces",
  BOOKING_DETAILS: "/trip/details/booking",
  ORDER_DETAILS_TRIP: "/trip/booking",
  ORDER_DETAILS_ARTWORK: "/artwork/order/details",
  PARTNER_PROFILE: "/partner/profile",
  MY_ACTIVITY: "/my-activity",
  INITIATE_REFUND: "/refund/initiate",
};

export const CHECKOUT_URL = {
  ARTWORK: "artwork/checkout",
  TRIP: "/trip/book",
  CART: "/cart/checkout",
  VERIFY_PAYMENT: "/order/payment/verify",
};

export const ADMIN_URL = {
  TRENDS_ANALYTICS: "/dashboard/admin/trends/insights",
  ARTWORK_ANALYTICS: "/dashboard/admin/artwork/insights",
  TRIPS_AND_PLACES_ANALYTICS: "/dashboard/admin/trips-and-places/insights",
  ADD_HASHTAG: "/dashboard/admin/hashtag/add",
  REMOVE_HASHTAG: "/dashboard/admin/hashtag/remove",
  ZONES_INSIGHTS: "/dashboard/admin/zones/insights",
  REPORTS_INSIGHTS: "/dashboard/reports/insights",
  ADMIN_SUMMARY: "/dashboard/admin/summary/insights",
  PARTNERS_INSIGHTS: "/dashboard/request/partner/insights",
  STORAGE_USAGE: "/dashboard/admin/storage/usage",
  LIST_USERS: "/dashboard/admin/users/list",
  UPDATE_USER_ROLE: "/dashboard/admin/users/role/update",
};

export const PARTNER_URL = {
  LIST_PARTNERS: "/dashboard/partner/list-partners",
  CREATE_TRIP: "/trips/create",
  TAGS_ANALYTICS: "/dashboard/partner/tags",
  REMOVE_TAG: "/dashboard/partner/tags/remove",
  DASHBOARD_SUMMARY: "/dashboard/partner/summary",
  DASHBOARD_PROGRESS: "/dashboard/partner/progress",
  DASHBOARD_TRIPS: "/dashboard/partner/trips",
  DASHBOARD_ARTWORKS: "/dashboard/partner/artworks",
  DASHBOARD_ARTWORKS_REQUESTS: "/dashboard/partner/artworks/requests",
  DASHBOARD_PLACES: "/dashboard/partner/places",
  ARTWORK_COLLABORATION_ACTION: "/artwork/status/update",
};

export const MODERATOR_URL = {
  LIST_MODERATORS: "/dashboard/moderator/list-moderators",
  PLATFORM_INSIGHTS: "/dashboard/platform-insights",
  ARTWORK_ANALYTICS: "/dashboard/moderator/artwork/insights",
  TRIPS_AND_PLACES_ANALYTICS: "/dashboard/moderator/trips-and-places/insights",
  PARTNERS_INSIGHTS: "/dashboard/request/partner/insights",
  MDERATOR_SUMMARY: "/dashboard/moderator/summary/insights",
  PLACES_SUMMARY: "/dashboard/moderator/places/insights",
  PLACE_STATUS_UPDATE: "/dashboard/moderator/places/status/update",
  RESOLVE_REPORT: "/report/resolve",
  REFUND_REQUESTS: "/dashboard/requests/refund",
  REFUND_ACTION: "/dashboard/requests/refund/action",
  UPDATE_SHARE_ARTWORK: "/dashboard/moderator/payments/artwork/shares/update",
  UPDATE_SHARE_TRIP: "/dashboard/moderator/payments/trip/shares/update",
};

export const ReducerConstant = {
  ROUTE_REDUCER: "routeReducer",
  PREQUOTE_REDUCER: "prequoteReducer",
  OCR_FILE_REDUCER: "ocrFileReducer",
};

export const Actions = {
  USER_DATA: "userData",
  IS_LOGIN: "isLogin",
  SEARCH: "search",
  PLACES_STATE: "placesState",
  PARTNER_STATE: "partnerState",
  ARTWORK_STATE: "artworkState",
};

export const RouteConstant = {
  HOME_PAGE: "",
  PLACES: "/places",
  ART_WORK: "/artworks",
  PARTNER: "/partners",
  PROFILE: "/profilePage",
  FORGET_PASSWORD: "/forgetpassword",
};

export const PAYMENT_METHOD = {
  CARD: "0",
  MOBILE: "1",
};

export const postsLimit = 20;
// export const SOCKET_SERVER_URL = "wss://tripcongo-socket.oodleslab.com"
// export const BACKEND_URL = "http://localhost:8080"
// export const BACKEND_URL = "https://tripcongo-be.oodleslab.com"
// export const SOCKET_SERVER_URL = "wss://uat-socket.momocd.com"
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
export const COUNTRY_CODE = "CG";

export const PROVINCES = [
  { label: "Kasaï-oriental", value: "kasaï-oriental" },
  { label: "Tshopo", value: "tshopo" },
  { label: "Ituri", value: "ituri" },
  { label: "Kongo-central", value: "kongo-central" },
  { label: "Maï-ndombe", value: "maï-ndombe" },
  { label: "Kwilu", value: "kwilu" },
  { label: "Kwango", value: "kwango" },
  { label: "Equateur", value: "equateur" },
  { label: "Sud-ubangi", value: "sud-ubangi" },
  { label: "Nord-ubangi", value: "nord-ubangi" },
  { label: "Mongala", value: "mongala" },
  { label: "Tshuapa", value: "tshuapa" },
  { label: "Bas-uele", value: "bas-uele" },
  { label: "Haut-uele", value: "haut-uele" },
  { label: "Nord-kivu", value: "nord-kivu" },
  { label: "Maniema", value: "maniema" },
  { label: "Lualaba", value: "lualaba" },
  { label: "Haut-lomami", value: "haut-lomami" },
  { label: "Tanganyika", value: "tanganyika" },
  { label: "Haut-katanga", value: "haut-katanga" },
  { label: "Sankuru", value: "sankuru" },
  { label: "Lomami", value: "lomami" },
  { label: "Kasaï-central", value: "kasaï-central" },
  { label: "Kasaï", value: "kasaï" },
  { label: "Sud-kivu", value: "sud-kivu" },
  { label: "Kinshasa", value: "kinshasa" },
];

export const ROUTES = {};
