import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// import trendsIcon from "../assets/icons/leftbar/trends.svg";
// import tripsIcon from "../assets/icons/leftbar/places.svg";
// import artWorkIcon from "../assets/icons/leftbar/artwork.svg";
// import partnerIcon from "../assets/icons/leftbar/partners.svg";

// import trendsIconFill from "../assets/images/trends_fill.svg";
// import tripsIconFill from "../assets/icons/leftbar/place_filled.svg";
// import artWorkIconFill from "../assets/icons/leftbar/artwork_filled.svg";
// import partnerIconFill from "../assets/icons/leftbar/partners_filled.svg";

import trendsIcon from "../assets/icons/leftbar/trendsIcon.png";
import tripsIcon from "../assets/icons/leftbar/tripsIcon.png";
import artWorkIcon from "../assets/icons/leftbar/artWorkIcon.png";
import partnerIcon from "../assets/icons/leftbar/partnerIcon.png";

import trendsIconFill from "../assets/icons/leftbar/trendsIconFill.png";
import tripsIconFill from "../assets/icons/leftbar/tripsIconFill.png";
import artWorkIconFill from "../assets/icons/leftbar/artWorkIconFill.png";
import partnerIconFill from "../assets/icons/leftbar/partnerIconFill.png";

import appleIcon from "../assets/icons/apple-icon.svg";
import androidIcon from "../assets/icons/android-icon.svg";

import ArtFilter from "../Components/Filters/ArtFilters";
import { GiHamburgerMenu } from "react-icons/gi";
// GiHamburgerMenu
import { useEffect, useRef, useState } from "react";
import TripFilters from "../Components/Filters/TripFilters";
import verifiedIcon from "../assets/icons/verifiedUserIcon.svg";
import { dateFormatOne, isActive, whichRegion } from "../helper";

import hamburgerIcon from "../assets/icons/leftbar/humbarger icon.svg";
import { getRequest } from "../Service/httpService";
import { API_URL, MODERATOR_URL } from "../Config/constant";
import { CheckoutCart } from "../Components/Cart/CheckoutCart";
import {
  showTripCheckout,
  showArtworkCheckout,
  selectedArtwork,
  selectedTrip,
  chatPartner,
  chatModalOpen,
  isLoading as setLoading,
  chatModalState,
} from "../store/reducers/routeReducer";
import { editProfile as setEditProfile } from "../store/reducers/routeReducer";

function LeftSideBar({ showNavOptions }) {
  const [insights, setInsights] = useState(null);
  let location = useLocation();
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(false);
  const [vw, setVw] = useState(0);
  const leftRef = useRef(null);
  const [showCartCheckout, setShowCartCheckout] = useState(false);
  const { isLogin, userData, isLoading, cart, editProfile } = useSelector(
    (state) => state.routeReducer
  );
  const dispatch = useDispatch();

  const leftPagesOptions = [
    {
      id: 1,
      name: "trends",
      image: trendsIcon,
      url: "/",
      activeimage: trendsIconFill,
    },
    {
      id: 2,
      name: "trips",
      image: tripsIcon,
      url: "/trips",
      activeimage: tripsIconFill,
    },
    {
      id: 3,
      name: "arts",
      image: artWorkIcon,
      url: "/artwork",
      activeimage: artWorkIconFill,
    },
    {
      id: 4,
      name:
        !userData || userData.role === "user"
          ? "partners"
          : userData.role === "admin"
            ? "admin"
            : `${userData.role}s`,
      image: partnerIcon,
      url: `/${!userData || userData.role == "user" ? "partner" : userData.role
        }`,
      activeimage: partnerIconFill,
    },
  ];

  // Detect a click outside the component so we can close the opened side bar in mobile view
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (leftRef.current && !leftRef.current.contains(event.target)) {
        if (vw < 993) {
          setShowSideBar(false);
        } else setShowSideBar(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSideBar, vw]);

  useEffect(() => {
    if (showSideBar) {
      setShowSideBar(false);
    }
  }, [location.pathname]);

  const OpenChat = async (chatWith) => {
    dispatch(setLoading(true));
    const url = `/user/profile/${chatWith}`;
    const data = await getRequest({ url });
    const user = data["data"][chatWith];
    dispatch(chatPartner(user));
    dispatch(chatModalState("chat"));
    dispatch(setLoading(false));
    setShowSideBar(false);
    dispatch(chatModalOpen(true));
  };

  // Detect a click outside the component so we can close the opened side bar in mobile view

  function tripFilters() {
    return (
      isLogin && (
        <div>
          <div className="horizontalLine" />
          <TripFilters />
        </div>
      )
    );
  }

  function uploadArtwork() {
    return (
      isLogin && (
        <div>
          <div className="horizontalLineWithMargin" />
          <button
            className="sellArtwork-button"
            onClick={(e) => {
              navigate("/artwork/upload");
            }}
          >
            Sell Your Artwork
          </button>

          <div className="horizontalLineWithMargin" />
          <ArtFilter />
        </div>
      )
    );
  }

  function PartnerLeftBar() {
    return (
      <div className="left-container">
        <div className="horizontalLine" />

        {/* <div className="w-full mb-3">
          <span>Welcome</span>
          <div className="name">
            <h3>{userData.name}</h3>
            <span
              onClick={(e) => {
                navigate("/profilePage");
              }}
            >
              Edit
            </span>
          </div>
        </div>
        <div className="mid">
          <div className="keys">
            <div className="key">
              <span>Agency</span>
            </div>
            <div className="key">
              <span>Region</span>
            </div>
            <div className="key">
              <span>Status</span>
            </div>
          </div>
          <div className="values">
            <div className="value">
              <span>Agency Name</span>
            </div>
            <div className="value">
              <span>{whichRegion(userData.state)}</span>
            </div>
            <div className="value">
              <span>
                Verified <img className="verify-icon" src={verifiedIcon} />{" "}
              </span>
              <span>{`since ${dateFormatOne(userData.createdAt)}`}</span>
            </div>
          </div>
        </div> */}
        <div className="bottom">
          <button
            className="create-trip"
            onClick={() => {
              navigate("/trips/create");
            }}
          >
            Create New Trip
          </button>
          <button
            className="create-place"
            onClick={() => {
              navigate("/places/create");
            }}
          >
            Create New Place
          </button>
        </div>
        <div className="horizontalLine" />
        <div className="partner-footer">
          <span>Contact Moderator</span>
          <span
            onClick={() => {
              OpenChat("moderator");
            }}
          >
            Chat
          </span>
          <span className="hidden">Report</span>
        </div>
      </div>
    );
  }

  function AdminLeftBar({ insights, setInsights }) {
    const getInsights = () => {
      getRequest({ url: MODERATOR_URL.PLATFORM_INSIGHTS })
        .then((res) => {
          setInsights(res.data);
        })
        .catch((err) => {
          console.error(err.message);
          return null;
        });
    };

    getInsights();

    return (
      <div className="left-container">
        <div className="horizontalLine" />

        {/* <div className="upper">
          <span>Welcome</span>
          <div className="name">
            <h3>{userData.name}</h3>
            <span
              onClick={(e) => {
                navigate("/profilePage");
              }}
            >
              Edit
            </span>
          </div>
        </div> */}
        {/* <div className="admin-left-info">
          <div className="title">
            <span>Here’s the MemoCD numbers:</span>
          </div>
          <div className="items">
            {insights &&
              insights.map((item) => (
                <div className="p-item">
                  <div className="item-left">
                    <span>{item.label}</span>
                  </div>
                  <div className="item-right">
                    <span>{item.value}</span>
                    {item.unsolved > 0 && (
                      <span>({item.unsolved} unresolved case)</span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div> */}
        <div className="horizontalLine" />
        <div className="partner-footer">
          <span>Contact Administor</span>
          <span
            onClick={(e) => {
              OpenChat("admin");
            }}
          >
            Chat
          </span>
          <span className="hidden">Report</span>
        </div>
      </div>
    );
  }

  function ModeratorLeftBar() {
    return (
      <div className="left-container">
        <div className="horizontalLine" />

        <div className="w-full mb-3">
          <span>Welcome</span>
          <div className="name">
            <h3>{userData.name}</h3>
            <span
              onClick={(e) => {
                navigate("/profilePage");
              }}
            >
              Edit
            </span>
          </div>
        </div>
        {/* <div className="admin-left-info">
          <div className="title">
            <span>Here’s the MemoCD numbers:</span>
          </div>
          <div className="items">
            {items.map((item) => (
              <div className="p-item">
                <div className="item-left">
                  <span>{item.label}</span>
                </div>
                <div className="item-right">
                  <span>{item.value}</span>
                  {item.unsolved > 0 && (
                    <span>({item.unsolved} unresolved case)</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div className="horizontalLine" />
        <div
          style={{ flexDirection: "column", gap: "4px" }}
          className="partner-footer"
        >
          <span>Contact Administrator</span>
          <div style={{ display: "flex", gap: "10px" }}>
            <span
              onClick={(e) => {
                OpenChat("admin");
              }}
            >
              Chat
            </span>
            <span className="hidden">Report</span>
          </div>
        </div>
      </div>
    );
  }

  function DashboardLeftbar() {
    if (userData.role === "partner") return PartnerLeftBar();
    else if (userData.role === "admin" || userData.role === "moderator")
      return <AdminLeftBar insights={insights} setInsights={setInsights} />;
    else if (userData.role === "moderator") return ModeratorLeftBar();
    else return <></>;
  }

  const CheckoutCartSection = () => {
    const cartAmount = () => {
      let amount = 0;
      for (const item of cart) {
        if (item.type === "trip") {
          amount += item.quantity * item.trip.price;
        } else {
          amount += item.quantity * item.artwork.price;
        }
      }
      return amount;
    };
    return (
      cart.length > 0 && (
        <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
          <div>
            <button
              className="w-[8rem] bg-[#1c4726] text-white rounded-md font-bold py-1"
              onClick={(e) => {
                setShowCartCheckout(true);
              }}
            >
              Checkout
            </button>
          </div>
        </div>
      )
    );
  };

  const LoveItBuyIt = () => {
    const page = location.pathname.split("/")[1];
    const id = location.pathname.split("/")[2];
    const OpenTripCheckout = () => {
      dispatch(showTripCheckout(true));
    };
    const OpenArtworkCheckout = () => {
      dispatch(selectedArtwork({ _id: id }));
      dispatch(showArtworkCheckout(true));
    };
    return (
      id &&
      id.length === 24 &&
      (page === "trips" ? (
        <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
          <button
            className="w-full bg-[#1c4726] text-white rounded-md font-bold py-1"
            onClick={OpenTripCheckout}
          >
            Buy trip
          </button>
        </div>
      ) : page === "places" ? (
        <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
          {userData && userData.role === "partner" ? (
            <button
              className="create-trip bg-memoGreen text-[14px] font-bold text-white  p-1 rounded-md"
              onClick={() => {
                navigate("/trips/create");
              }}
            >
              Create New Trip
            </button>
          ) : null}
          {/* <Link
            to="https://start.memocd.com/"
            className="w-full flex justify-center items-center bg-[#1c4726] text-white rounded-md font-bold py-1"
          >
            Learn More
          </Link> */}
        </div>
      ) : (
        <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
          <button
            style={{
              border: "1px solid #dfb82d",
            }}
            className="w-full text-[#dfb82d] rounded-md font-bold py-1"
            onClick={OpenArtworkCheckout}
          >
            Love it? Buy it
          </button>
        </div>
      ))
    );
  };

  const artworkForm = () => {
    return (
      <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
        {/* <Link
          to="https://start.memocd.com/"
          className="w-full flex justify-center items-center bg-[#1c4726] text-white rounded-md font-bold py-1"
        >
          Learn More
        </Link> */}
      </div>
    );
  };

  const tripBooking = () => {
    return (
      <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
        {/* <Link
          to="https://start.memocd.com/"
          className="w-full flex justify-center items-center bg-[#1c4726] text-white rounded-md font-bold py-1"
        >
          Learn More
        </Link> */}
      </div>
    );
  };

  const EditProfile = () => {
    return (
      !editProfile && (
        <div className="w-full flex flex-col gap-2 rounded-md p-2 ">
          <button
            className="w-full flex justify-center items-center bg-[#1c4726] text-white rounded-md font-bold pt-2 py-1"
            onClick={(e) => {
              dispatch(setEditProfile(true));
            }}
          >
            Edit Profile
          </button>
        </div>
      )
    );
  };

  const handleResize = () => {
    setVw(window.innerWidth);
  };

  useEffect(() => {
    if (vw === 0) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (vw === 0) handleResize();
    else if (vw < 993) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }, [vw]);

  const newPlace = () => {
    return (
      <div>
        <div className="horizontalLineWithMargin" />
        <button
          className="create-place"
          onClick={() => {
            navigate("/places/create");
          }}
        >
          New Place
        </button>
      </div>
    );
  };

  return (
    <>
      {/* {showSideBar ? <div className="w-screen h-screen bg-black opacity-80 z-50 fixed" /> : <></>} */}
      <div className={`left ${showSideBar ? "open" : ""}`} ref={leftRef}>
        {!showSideBar && showNavOptions && (
          <div
            className="hamburger"
            onClick={() => {
              setShowSideBar(!showSideBar);
            }}
          >
            {!isLoading && <img src={hamburgerIcon} alt="" />}
          </div>
        )}
        <div>

          <div className="sidebar">
            {leftPagesOptions.map((item) =>
              isActive(location.pathname, item.name) ? (
                <a
                  key={item.id}
                  className={`menu-item active`}
                  onClick={() => {
                    navigate(item.url);
                    setShowSideBar(vw >= 993);
                  }}
                >
                  <img src={item.activeimage} alt="" />
                  <h3>{item.name}</h3>
                </a>
              ) : (
                <a
                  key={item.id}
                  className={`menu-item inactive`}
                  onClick={() => {
                    navigate(item.url);
                    setShowSideBar(vw >= 993);
                  }}
                >
                  <img src={item.image} alt="" />
                  <h3>{item.name}</h3>
                </a>
              )
            )}
          </div>

          {location.pathname === "/trips" ? tripFilters() : null}

          {location.pathname === "/artwork" ? uploadArtwork() : null}
          {location.pathname === "/trips/create" ? newPlace() : null}
          {`/${location.pathname.split("/")[1]}/${location.pathname.split("/")[2]
            }` === "/partner/profile" && userData.role === "user" ? (
            <div>
              <div className="h-[1px] bg-[#e5e5e5] w-full my-4"></div>
              <Link
                to="/partner/register"
                className="w-[90%] aspect-[6] py-1 cursor-pointer font-bold text-md flex justify-center items-center rounded-md text-white bg-[#1C4726] "
              >
                Be yourself a partner
              </Link>
            </div>
          ) : null}

          {(location.pathname === "/partner" ||
            location.pathname === "/moderator" ||
            location.pathname === "/admin") &&
            userData ? (
            <DashboardLeftbar insights={insights} setInsights={setInsights} />
          ) : null}
          <div className="horizontalLine opacity-0" />

          {location.pathname === "/" ? (
            <div className="bottom-download-app-side-bar">
              <div className="following-hashtags">
                <div className="following-hashtags-top">
                  <p>
                    Publish on your networks with one or more following hashtag.
                  </p>
                </div>
                <Hashtags text="Publish on your networks with one or more following hashtag." />
              </div>
              <div className="horizontalLine" />
            </div>
          ) : null}
          {location.pathname === "/cart" && CheckoutCartSection()}
          {(location.pathname.split("/")[1] === "artwork" ||
            location.pathname.split("/")[1] === "trips" ||
            location.pathname.split("/")[1] === "places") &&
            LoveItBuyIt()}
          {location.pathname === "/artwork/upload" && artworkForm()}
          {location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2] +
            "/" +
            location.pathname.split("/")[3] ===
            "trips/booking/details" && tripBooking()}
          {location.pathname === "/profilePage" && EditProfile()}

          <CheckoutCart
            cart={cart}
            show={showCartCheckout}
            setShow={setShowCartCheckout}
          />
        </div>

        <div className="text-xs flex flex-wrap gap-1">
          <div className="flex items-center justify-center space-x-2  h-4" >

            <Link
              className="hover:underline !text-gray-600 hover:!text-blue-600"
              target="_blank"
              to="https://start.memocd.com/conditions-d-utilisation"
            >
              Terms of Service
            </Link>

            <div className="text-gray-500 ">•</div>
          </div>
          <div className="flex items-center justify-center space-x-2  h-4" >

            <Link
              className="hover:underline !text-gray-600 hover:!text-blue-600"
              target="_blank"
              to="https://start.memocd.com/politique-de-confidentialite"
            >
              Privacy Policy
            </Link>

            <div className="text-gray-500 ">•</div>
          </div>
          <div className="flex items-center space-x-2  h-4" >

            <Link
              className="hover:underline !text-gray-600 hover:!text-blue-600"
              target="_blank"
              to="https://start.memocd.com/modalites-de-paiement"
            >
              Payment Terms
            </Link>
            <div className="text-gray-500 ">•</div>

          </div>

        </div>
        <div className="download-icons">
          <img src={appleIcon} alt="" />
          <img src={androidIcon} alt="" />
        </div>
        <Link to="https://tripcongo.com" target="blank" className="black-logo-name font-normal hover:underline hover:text-gray-500">
          <p>TripCongo LLC © {(new Date()).getFullYear()}</p>
        </Link>
      </div>
    </>
  );
}

function Hashtags({ text }) {
  const [tags, setTags] = useState([]);

  const getHashtags = () => {
    getRequest({
      url: API_URL.TRENDS_HASHTAGS,
    })
      .then((res) => {
        setTags(res.data.data.hashtags);
      })
      .catch((err) => {
        setTags([]);
        console.error(err.message);
      });
  };

  useEffect(() => {
    getHashtags();
  }, []);

  return (
    <div className="following-hashtags-bottom">
      {tags.map((tag, index) => (
        <div key={index} style={{ display: "block" }}>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => <Tooltip {...props}>{text}</Tooltip>}
            placement="top"
          >
            <span># {tag}</span>
          </OverlayTrigger>
        </div>
      ))}
    </div>
  );
}

export default LeftSideBar;
