import React, { useEffect, useState } from "react";
import styles from "./artFilters.module.css";
import searchIcon from "../../assets/icons/searchIcon.svg";
import checked from "../../assets/icons/checkbox-checked-icon.svg";
import unChecked from "../../assets/icons/checkbox-unchecked-icon.svg";
import openedFilter from "../../assets/icons/opened-filter-art.svg";
import closedFilter from "../../assets/icons/closed-filter-art.svg";
import { getRequest } from "../../Service/httpService";
import { API_URL } from "../../Config/constant";

import { artworkFilter as setFilters } from "../../store/reducers/routeReducer";
import { useDispatch, useSelector } from "react-redux";

const ArtFilters = () => {
  const { artworkFilter: filters } = useSelector((state) => state.routeReducer);

  const [showFilters, setShowFilters] = useState(false);
  const [minPrice, setMinPrice] = useState(filters ? filters.minPrice : 0);
  const [maxPrice, setMaxPrice] = useState(filters ? filters.maxPrice : 100);
  const [typeSearch, setTypeSearch] = useState("");
  const [artworkTypes, setArtworkTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState(
    filters ? filters.types : []
  );

  const [unique, setUnique] = useState(filters ? filters.unique : false);
  const [newOnly, setNewOnly] = useState(filters ? filters.newOnly : false);
  const [onOrder, setOnOrder] = useState(filters ? filters.onOrder : false);

  const [provinceSearch, setProvinceSearch] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [selectedProvinces, setSelectedProvinces] = useState(
    filters ? filters.provinces : []
  );

  const dispatch = useDispatch();

  const apply = () => {
    const filter = {
      minPrice,
      maxPrice,
      types: selectedTypes,
      provinces: selectedProvinces,
      unique,
      onOrder,
      newOnly,
    };

    dispatch(setFilters(filter));
  };

  const reset = () => {
    setMinPrice(0);
    setMaxPrice(1000);
    setTypeSearch("");
    setSelectedTypes([]);
    setUnique(false);
    setNewOnly(false);
    setOnOrder(false);
    setProvinceSearch("");
    setSelectedProvinces([]);

    const filter = {
      minPrice: 0,
      maxPrice: 1000,
      types: [],
      provinces: [],
      unique: false,
      onOrder: false,
      newOnly: false,
    };

    dispatch(setFilters(filter));
  };

  const getArtworkTypes = () => {
    getRequest({
      url: API_URL.ARTWORK_TYPES + "?search=" + typeSearch,
    })
      .then((res) => {
        setArtworkTypes(res.data.data);
      })
      .catch((err) => console.error(err.message));
  };

  const getProvinces = () => {
    getRequest({
      url: API_URL.ARTWORK_PROVINCES + "?search=" + provinceSearch,
    })
      .then((res) => {
        setProvinces(res.data.data);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    getArtworkTypes();
  }, [typeSearch]);

  useEffect(() => {
    getProvinces();
  }, [provinceSearch]);

  return (
    <div className={styles["art-filters"]}>
      <div className={styles["header"]}>
        <h3>Filters</h3>
        <img
          src={showFilters ? openedFilter : closedFilter}
          alt=""
          onClick={() => setShowFilters(!showFilters)}
        />
      </div>
      {showFilters && (
        <>
          <PriceFilter
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
          />
          <TypeFilter
            typeSearch={typeSearch}
            artworkTypes={artworkTypes}
            selectedTypes={selectedTypes}
            setTypeSearch={setTypeSearch}
            setSelectedTypes={setSelectedTypes}
          />
          <OtherFilters
            unique={unique}
            onOrder={onOrder}
            newOnly={newOnly}
            setUnique={setUnique}
            setOnOrder={setOnOrder}
            setNewOnly={setNewOnly}
          />
          <LocationFilter
            provinces={provinces}
            provinceSearch={provinceSearch}
            selectedProvinces={selectedProvinces}
            setSelectedProvinces={setSelectedProvinces}
            setProvinceSearch={setProvinceSearch}
          />
          <Buttons apply={apply} reset={reset} />
        </>
      )}
    </div>
  );
};

function PriceFilter({ minPrice, maxPrice, setMinPrice, setMaxPrice }) {
  const manageMinPrice = (e) => {
    setMinPrice(
      e.target.value == "" ? "" : Math.min(Math.abs(e.target.value), maxPrice)
    );
    setMinPrice(() => e.target.value);
  };
  const manageMaxPrice = (e) => {
    // setMaxPrice(
    //   e.target.value == "" ? "" : Math.max(minPrice, Math.abs(e.target.value))
    // );
    setMaxPrice(() => e.target.value);
  };
  return (
    <div className={styles["price-filter"]}>
      <div className={styles["label"]}>
        <span>Price</span>
      </div>
      <div className={styles["filter"]}>
        <div className={styles["filter-item"]}>
          <div className={styles["label"]}>Min</div>
          <div className={styles["filter-input"]}>
            <span className={styles["input-box"]}>
              <input
                type="number"
                value={minPrice}
                onBlur={() => {
                  setMinPrice((minPrice) =>
                    minPrice == "" ? "" : Math.min(minPrice, maxPrice)
                  );
                }}
                onChange={manageMinPrice}
              />
            </span>
            <span className={styles["currency-box"]}>
              <input type="text" value="USD" disabled />
            </span>
          </div>
        </div>
        <div className={styles["to"]}>to</div>
        <div className={styles["filter-item"]}>
          <div className={styles["label"]}>Max</div>
          <div className={styles["filter-input"]}>
            <span className={styles["input-box"]}>
              <input
                type="number"
                value={maxPrice}
                onBlur={() => {
                  setMaxPrice((maxPrice) =>
                    maxPrice == "" ? "" : Math.max(minPrice, maxPrice)
                  );
                }}
                onChange={manageMaxPrice}
              />
            </span>
            <span className={styles["currency-box"]}>
              <input type="text" value="USD" disabled />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function TypeFilter({
  artworkTypes,
  search,
  setTypeSearch,
  selectedTypes,
  setSelectedTypes,
}) {
  const handleCheck = (type) => {
    if (selectedTypes.includes(type._id)) {
      const updatedCheckes = selectedTypes.filter((item) => item !== type._id);
      setSelectedTypes(updatedCheckes);
    } else {
      const updatedCheckes = [...selectedTypes, type._id];
      setSelectedTypes(updatedCheckes);
    }
  };

  const [showAll, setShowAll] = useState(false);

  return (
    <div className="type-filter">
      <div className={styles["label"]}>
        <span>Type</span>
      </div>

      <div className={styles["search"]}>
        <img src={searchIcon} className={styles["search-icon"]} />
        <span className={styles["vertical-line"]} />
        <input
          type="text"
          value={search}
          onChange={(e) => {
            setTypeSearch(e.target.value);
          }}
          placeholder="Search Type"
        />
      </div>
      <div className={styles["suggestions"]}>
        {artworkTypes?.map(
          (type, index) => {
            if (!showAll && index > 4) return <></>;
            else return (
              <div key={index} className={styles["suggestion"]}>
                <div
                  className={styles["left"]}
                  onClick={() => {
                    handleCheck(type);
                  }}
                >
                  <img
                    src={selectedTypes.includes(type._id) ? checked : unChecked}
                  />
                  <span
                    className={
                      styles[
                      selectedTypes.includes(type._id)
                        ? "check-label"
                        : "uncheck-label"
                      ]
                    }
                  >
                    {type.label}
                  </span>
                </div>
                <span
                  className={
                    styles[
                    selectedTypes.includes(type._id)
                      ? "count-check"
                      : "count-uncheck"
                    ]
                  }
                >
                  {type.artworks_count}
                </span>
              </div>
            )
          }
        )}
        {artworkTypes?.length > 5 && (
          <div onClick={() => { setShowAll(prev => !prev) }} className={styles["more"]}>{showAll ? `See Less` : `+${artworkTypes?.length - 5} more`}</div>
        )}
      </div>
    </div>
  );
}

function OtherFilters({
  unique,
  onOrder,
  setUnique,
  setOnOrder,
  newOnly,
  setNewOnly,
}) {
  return (
    <div className="type-filter">
      <div className={styles["label"]}>
        <span>Others</span>
      </div>
      <div className={styles["suggestions"]}>
        <div className={styles["suggestion"]}>
          <div
            className={styles["left"]}
            onClick={() => {
              setUnique(!unique);
            }}
          >
            <img src={unique ? checked : unChecked} />
            <span className={styles[unique ? "check-label" : "uncheck-label"]}>
              Unique Art
            </span>
          </div>
        </div>

        <div className={styles["suggestion"]}>
          <div
            className={styles["left"]}
            onClick={() => {
              setNewOnly(!newOnly);
            }}
          >
            <img src={newOnly ? checked : unChecked} />
            <span className={styles[newOnly ? "check-label" : "uncheck-label"]}>
              New
            </span>
          </div>
        </div>

        <div className={styles["suggestion"]}>
          <div
            className={styles["left"]}
            onClick={() => {
              setOnOrder(!onOrder);
            }}
          >
            <img src={onOrder ? checked : unChecked} />
            <span className={styles[onOrder ? "check-label" : "uncheck-label"]}>
              On Order
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function LocationFilter({
  provinces,
  provinceSearch,
  setProvinceSearch,
  selectedProvinces,
  setSelectedProvinces,
}) {
  const handleCheck = (type) => {
    if (selectedProvinces.includes(type.province)) {
      const updatedCheckes = selectedProvinces.filter(
        (item) => item !== type.province
      );
      setSelectedProvinces(updatedCheckes);
    } else {
      const updatedCheckes = [...selectedProvinces, type.province];
      setSelectedProvinces(updatedCheckes);
    }
  };

  const [showAll, setShowAll] = useState(false);

  return (
    <div className="type-filter">
      <div className={styles["label"]}>
        <span>Location</span>
      </div>

      <div className={styles["search"]}>
        <img src={searchIcon} className={styles["search-icon"]} />
        <span className={styles["vertical-line"]} />
        <input
          type="text"
          value={provinceSearch}
          onChange={(e) => {
            setProvinceSearch(e.target.value);
          }}
          placeholder="Search Type"
        />
      </div>
      <div className={styles["suggestions"]}>
        {provinces &&
          provinces.map(
            (type, index) => {
              if (!showAll && index > 4) return <></>;
              (
                <div key={index} className={styles["suggestion"]}>
                  <div
                    className={styles["left"]}
                    onClick={() => {
                      handleCheck(type);
                    }}
                  >
                    <img
                      src={
                        selectedProvinces.includes(type.province)
                          ? checked
                          : unChecked
                      }
                    />
                    <span
                      className={
                        styles[
                        selectedProvinces.includes(type.province)
                          ? "check-label"
                          : "uncheck-label"
                        ]
                      }
                    >
                      {type.province}
                    </span>
                  </div>
                  <span
                    className={
                      styles[
                      selectedProvinces.includes(type.province)
                        ? "count-check"
                        : "count-uncheck"
                      ]
                    }
                  >
                    {type.artwork_count}
                  </span>
                </div>
              )
            }

          )}
        {provinces?.length > 5 && (
          <div onClick={() => { setShowAll(prev => !prev) }} className={styles["more"]}>{showAll ? `See Less` : `+${provinces?.length - 5} more`}</div>
        )}
      </div>
    </div>
  );
}

function Buttons({ apply, reset }) {
  return (
    <div className={styles["buttons"]}>
      <button
        className={styles["reset"]}
        onClick={async () => {
          await reset();
        }}
      >
        Reset
      </button>
      <button className={styles["apply"]} onClick={apply}>
        Apply
      </button>
    </div>
  );
}

export default ArtFilters;
