import React, { useEffect, useRef, useState } from "react";
import styles from "./notification.module.css";
import { getRequest } from "../../Service/httpService";
import { API_URL } from "../../Config/constant";
import { Link } from "react-router-dom";
import { format } from "timeago.js";

import noData from "../../assets/illustrations/no-data.png";

import CircularProgress from "@mui/material/CircularProgress";

const Notification = ({
  showNotifications,
  setShowNotifications,
  setNotificationCount,
}) => {
  const notificationRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [seeAll, setSeeAll] = useState(false);

  const [isLoading, setisLoading] = useState(true);

  // custom css
  const dontShow = {
    height: "0px",
    width: "0px",
    zIndex: 100,
  };
  const show = {
    height: "fit-content",
    maxHeight: "400px",
    display: 1,
  };
  // custom css

  async function getNotifications() {
    setisLoading(true);

    await getRequest({ url: API_URL.GET_NOTIFICATIONS })
      .then((res) => {
        setNotifications(res.data.data);
        setNotificationCount(0);
      })
      .catch((err) => {
        console.error(err.message);
      });

    setisLoading(false);
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        height: showNotifications ? "100vh" : "0",
        width: showNotifications ? "100vw" : "0",
        right: "0",
        top: "0",
      }}
      className={`absolute ${
        showNotifications ? "h-screen w-screen" : "h-0 w-0"
      } top-0 right-0`}
      onClick={() => {
        setShowNotifications();
      }}
    >
      <div
        className={styles.notifiBox}
        style={showNotifications ? show : dontShow}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <h6 style={{ fontWeight: "400", color: "gray" }}>Notifications</h6>
        </div>
        <div className=" flex flex-col gap-2">
          {isLoading ? (
            <CircularProgress color="success" />
          ) : notifications?.notifications?.length > 0 ? (
            notifications?.notifications?.map((notification, index) => (
              <Link
                to={notification.postId}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setShowNotifications(false);
                }}
              >
                {/* className={styles.notification} */}
                <div key={index} className="py-2 px-4 hover:bg-green-50">
                  <div className="flex items-center gap-x-4 ">
                    {notification?.sender?.avatar ? (
                      <div className={styles.leftSection}>
                        <img src={notification?.sender?.avatar} />
                      </div>
                    ) : (
                      <div className="bg-[#004F27] w-12 h-12 p-2 aspect-square rounded-full text-white font-bold flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-full h-full"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                          />
                        </svg>
                      </div>
                    )}
                    <div className={styles.middleSection}>
                      <div className={styles.middleUpper}>
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          {notification?.userName
                            ? notification?.userName
                            : "Admin"}
                        </span>
                        <span style={{ fontSize: "12px", color: "gray" }}>
                          {format(notification.createdAt)}
                        </span>
                      </div>
                      <div className={styles.middleLower}>
                        <span>{notification.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.rightSection}>
                    {/* <span>
                  <img src={notification.post} alt="" />
                </span> */}
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="w-full flex items-center flex-col gap-4 justify-center">
              <img src={noData} className="w-1/2" />
              <h1 className="text-2xl">Whoops! Nothing here</h1>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          {/* {!seeAll ? (
            <h6
              style={{ fontWeight: "400", color: "green", cursor: "pointer" }}
              onClick={() => {
                setSeeAll(true);
                setShowNotifications(true);
              }}
            >
              View All Notifications
            </h6>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Notification;
