import { useState, useEffect, useRef } from "react";
import check from "../../assets/icons/radio-check.svg";
import uncheck from "../../assets/icons/radio-uncheck.svg";
import Modal from "react-bootstrap/Modal";
import { Select } from "../../Elements/CustomSelect.tsx";
import styles from "./checkout.module.css";
import { CHECKOUT_URL, PAYMENT_METHOD, PROVINCES } from "../../Config/constant";
import { getRequest, postRequest } from "../../Service/httpService.js";
import { useDispatch, useSelector } from "react-redux";
import { isLoading as setLoading } from "../../store/reducers/routeReducer.js";
import loader from "../../assets/icons/loader.gif";
import phoneGif from "../../assets/gifs/mobile.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showTripCheckout as setShow } from "../../store/reducers/routeReducer.js";

export const CheckoutTrip = (props) => {
  const { selectedTrip: trip, showTripCheckout: show } = useSelector(
    (state) => state.routeReducer
  );

  const [ticketChild, setTicketChild] = useState(0);
  const [ticketAdult, setTicketAdult] = useState(1);
  const [ticketCouple, setTicketCouple] = useState(0);
  const [paymentUnderProcess, setPaymentUnderProcess] = useState(false);
  const [orderReference, setOrderReference] = useState(null);
  const handleClose = () => dispatch(setShow(true));
  const [stock, setStock] = useState(1);
  const [waitTime, setWaitTime] = useState(10); /// in seconds
  const [contactNumber, setContactNumber] = useState(null);
  const myRef = useRef(null);
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState();
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState(null);
  const [errors, setErrors] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.MOBILE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const durationInSeconds = 120; // 2 minutes
  const API_INTERVAL = 5;

  const validate = () => {
    let temp = null;
    if (!contactNumber) {
      temp = { ...temp, contactNumber: "Phone is mandatory." };
    }
    const total = ticketAdult + ticketChild + ticketCouple;
    if (total === 0) {
      temp = { ...temp, ticketAdult: "Book at least 1 ticket to proceed" };
    }
    if (contactNumber?.length !== 9) {
      temp = { ...temp, contactNumber: "Contact Number must be 9 characters" };
    }
    return temp;
  };

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(setShow(false));
          setTicketAdult(0);
          setTicketChild(0);
          setTicketCouple(0);
          setContactNumber(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  const verifyPaymentStatus = () => {
    if (!orderReference) return;
    getRequest({
      url: `${CHECKOUT_URL.VERIFY_PAYMENT}?page=trips&reference=${orderReference}`,
    })
      .then((res) => {
        if (res.data.verified) {
          setPaymentUnderProcess(false);
          dispatch(setShow(false));
          navigate(
            `/trips?page=trips&reference=${orderReference}`
          );
        }
      })
      .catch((err) => {
        dispatch(setShow(true));
      });
  };

  const checkoutUsingMobileBanking = (data) => {
    dispatch(setShow(false));
    setTicketAdult(0);
    setTicketChild(0);
    setTicketCouple(0);
    setContactNumber(null);
    dispatch(setLoading(true));

    postRequest({
      url: `${CHECKOUT_URL.TRIP}`,
      data,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.data.message);
        } else {
          setOrderReference(res.data.data.reference);
          setPaymentUnderProcess(true);
          dispatch(setShow(true));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setLoading(false));
      });
  };

  const checkoutUsingCard = (data) => {
    dispatch(setLoading(true));
    dispatch(setShow(false));
    setTicketAdult(0);
    setTicketChild(0);
    setTicketCouple(0);
    setContactNumber(null);
    postRequest({
      url: CHECKOUT_URL.TRIP,
      data,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.data.message);
        } else {
          window.open(res.data.data.url, "_self");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setLoading(false));
      });
  };

  const Checkout = () => {
    const error = validate();
    if (error) {
      setErrors(error);
      return;
    }

    const tickets = {
      adult: ticketAdult,
      child: ticketChild,
      couple: ticketCouple,
      total: parseInt(ticketAdult) + 2 * ticketCouple + parseInt(ticketChild),
    };

    const data = {
      trip_id: trip._id,
      tickets: JSON.stringify(tickets),
      phone: "243" + contactNumber,
      type: paymentMethod,
    };

    if (paymentMethod === PAYMENT_METHOD.CARD) checkoutUsingCard(data);
    else checkoutUsingMobileBanking(data);
  };

  // timer
  useEffect(() => {
    let interval;

    if (paymentUnderProcess) {
      interval = setInterval(() => {
        if (waitTime === 0) {
          setPaymentUnderProcess(false);
          dispatch(setShow(true));
          navigate(`/trips?page=trips&reference=${orderReference}`);
        }

        if (waitTime % API_INTERVAL === 0) {
          verifyPaymentStatus();
        }

        if (waitTime > 0) {
          setWaitTime(waitTime - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [paymentUnderProcess, waitTime]);

  // Reset the timer when processing becomes true
  useEffect(() => {
    if (paymentUnderProcess) {
      setWaitTime(durationInSeconds);
    }
  }, [paymentUnderProcess]);

  useOutsideClick(myRef, setShow);

  return paymentUnderProcess ? (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className="w-full flex justify-center items-center flex-col py-[5rem] px-10 gap-6">
          <img className="w-[50%]" src={phoneGif} alt="" />
          <div className="text-lg text-center">
            Your payment has been initiated. Unlocked
            your phone to validate the popup with your
            code.
          </div>
          <div className="text-lg text-center text-[#E15241]">
            Do not refresh the page
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className={styles["container"]} ref={myRef}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6 absolute right-4 top-4 cursor-pointer text-black"
            onClick={() => {
              dispatch(setShow(false));

              setTicketAdult(0);
              setTicketChild(0);
              setTicketCouple(0);
              setContactNumber(null);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>

          <div className={styles["trip-details"]}>
            <div className={styles["header"]}>
              <h3 className="mb-2">Proceed with Checkout</h3>
            </div>
            <div className={styles["trip-details-container"]}>
              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Number of adults{" "}
                  <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <span className={styles["ticket-price"]}>
                  <span>
                    <input
                      type="number"
                      value={ticketAdult}
                      onChange={(e) => {
                        setTicketAdult(
                          e.target.value
                            ? Math.abs(e.target.value)
                            : e.target.value
                        );
                      }}
                    />
                  </span>
                </span>

                {errors?.ticketAdult && (
                  <span className="text-xs text-red-500">
                    {errors?.ticketAdult}
                  </span>
                )}
              </div>
              {trip && trip.priceChild && (
                <>
                  <div className={styles["item-100"]}>
                    <div className={styles["label"]}>
                      Number of child{" "}
                      <span className={styles["mandatory"]}>*</span>{" "}
                    </div>
                    <span className={styles["ticket-price"]}>
                      <span>
                        <input
                          type="number"
                          value={ticketChild}
                          onChange={(e) =>
                            setTicketChild(
                              e.target.value
                                ? Math.abs(e.target.value)
                                : e.target.value
                            )
                          }
                        />
                      </span>
                    </span>
                  </div>

                  {/* <div className={styles["item-100"]}>
                    <div className={styles["label"]}>
                      Number of couples{" "}
                      <span className={styles["mandatory"]}>*</span>{" "}
                    </div>
                    <span className={styles["ticket-price"]}>
                      <span>
                        <input
                          type="number"
                          value={ticketCouple}
                          onChange={(e) => setTicketCouple(e.target.value)}
                        />
                      </span>
                    </span>
                  </div> */}
                </>
              )}

              <div className=" bg-slate-100 p-4 rounded-lg ">
                <div className="flex items-center gap-x-2 text-gray-500">
                  <div className="text-sm font-semibold whitespace-nowrap">
                    Phone Number <span className={styles["mandatory"]}>*</span>{" "}
                  </div>
                  <div className="flex bg-white overflow-hidden rounded-xl">
                    <div className="rounded-xl text-sm p-2 pr-0">+243</div>
                    <input
                      className="bg-inherit rounded-none text-sm pl-0 p-2"
                      type="number"
                      placeholder="Enter Number"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </div>
                  <span className="text-[0.5rem]">
                    To be also used in case of Mobile Payment
                  </span>
                </div>

                {errors?.contactNumber && (
                  <span className="text-xs text-red-500">
                    {errors?.contactNumber}
                  </span>
                )}

                <div className="bg-slate-200 text-gray-500 font-semibold p-2 px-3 rounded-lg my-4 flex items-center justify-between">
                  <span>Total</span>
                  <span className="text-2xl">
                    $ {(ticketAdult * trip?.price) + (ticketChild * trip.priceChild || 0) + (ticketCouple * trip.priceCouple || 0)}
                    {/* {new Intl.NumberFormat().format(
                      (ticketAdult % 2
                        ? ((ticketAdult - 1) / 2) * trip?.priceCouple +
                        trip?.price
                        : trip?.priceCouple * (ticketAdult / 2)) +
                      trip?.priceChild * ticketChild
                    )} */}
                  </span>
                </div>

                <div className="flex flex-col gap-y-2">
                  <div className="text-[0.7rem] text-gray-500 font-semibold whitespace-nowrap">
                    How do you want to pay ?
                  </div>

                  <div className="flex gap-x-2">
                    <span
                      onClick={() => {
                        setPaymentMethod(PAYMENT_METHOD.MOBILE);
                      }}
                      className={`px-4 py-1 ${paymentMethod == PAYMENT_METHOD.MOBILE
                        ? "bg-[#FF8600] text-white"
                        : "bg-white"
                        } rounded-full  text-[0.7rem] leading-none flex items-center cursor-pointer font-semibold`}
                    >
                      Mobile Money
                    </span>

                    <span
                      onClick={() => {
                        setPaymentMethod(PAYMENT_METHOD.CARD);
                      }}
                      className={`px-4 py-1 ${paymentMethod == PAYMENT_METHOD.CARD
                        ? "bg-[#FF8600] text-white"
                        : "bg-white"
                        } rounded-full  text-[0.7rem] leading-none flex items-center cursor-pointer font-semibold`}
                    >
                      Debit Card
                    </span>
                  </div>
                </div>

                <div className="flex justify-center items-center my-4">
                  <button
                    className="bg-[#5AC4F6] text-white font-semibold px-8 py-2 rounded-full"
                    onClick={(e) => {
                      Checkout(e);
                    }}
                  >
                    Checkout
                  </button>
                </div>

                <div className="text-gray-500  text-[0.6rem] flex flex-col">
                  <span>
                    Mobile Money: m-Pesa, Airtel Money, Orange Money, Africell
                    Money.
                  </span>
                  <span>Debit card: VISA, Master Card. </span>
                  <span>Couple prices applies from 2.</span>
                  <span>Children must be between 2 and 18 years old.</span>
                  <span>All children must be accompanied by adults.</span>
                </div>
              </div>

              {/* <div className={styles["item-100"]}>
                <div className={styles["label"]}>How do you want to pay ?</div>
                <div
                  className={styles["check-box-container"]}
                  style={{ flexDirection: "column", alignItems: "start" }}
                >
                  <div
                    className={styles["check-box"]}
                    onClick={() => {
                      setPaymentMethod(PAYMENT_METHOD.CARD);
                    }}
                  >
                    <span>
                      <img
                        src={
                          paymentMethod === PAYMENT_METHOD.CARD
                            ? check
                            : uncheck
                        }
                        alt=""
                      />
                    </span>
                    <span
                      className={
                        styles[
                          paymentMethod === PAYMENT_METHOD.CARD
                            ? "text-green"
                            : "text-inactive"
                        ]
                      }
                    >
                      Debit Card
                    </span>
                  </div>
                  <div
                    className={styles["check-box"]}
                    onClick={() => {
                      setPaymentMethod(PAYMENT_METHOD.MOBILE);
                    }}
                  >
                    <span>
                      <img
                        src={
                          paymentMethod === PAYMENT_METHOD.MOBILE
                            ? check
                            : uncheck
                        }
                        alt=""
                      />
                    </span>
                    <span
                      className={
                        styles[
                          paymentMethod === PAYMENT_METHOD.MOBILE
                            ? "text-green"
                            : "text-inactive"
                        ]
                      }
                    >
                      Mobile Banking
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles["create"]}>
                <button
                  onClick={(e) => {
                    Checkout(e);
                  }}
                >
                  Checkout
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
