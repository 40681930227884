import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';



export const PartnerOnlyRoute = ({ Component }) => {
  const { userData: user } = useSelector((state) => state.routeReducer);
  const isAuthenticated = user && user.role === "partner";
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component />;
};

export const AdminOnlyRoute = ({ Component }) => {
  const { userData: user } = useSelector((state) => state.routeReducer);
  const isAuthenticated = user && user.role === "admin";
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component />;
};

export const ModeratorOnlyRoute = ({ Component }) => {
  const { userData: user } = useSelector((state) => state.routeReducer);
  const isAuthenticated = user && user.role === "moderator";
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component />;
};

export const ModeratorAndAdminRoute = ({ Component }) => {
  const { userData: user } = useSelector((state) => state.routeReducer);
  const isAuthenticated = user && (user.role === "moderator" || user.role === "admin");
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component />;
};


export const LoggedInUserOnlyRoute = ({ Component }) => {
  const { isLogin: isAuthenticated } = useSelector((state) => state.routeReducer);
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component />;
};



