import { useState, useEffect, useRef } from "react";
import checkGold from "../../assets/icons/check-gold.svg";
import uncheckGold from "../../assets/icons/uncheck-gold.svg";
import Modal from "react-bootstrap/Modal";
import { Select } from "../../Elements/CustomSelect.tsx";
import styles from "./checkout.module.css";
import { CHECKOUT_URL, PAYMENT_METHOD, PROVINCES } from "../../Config/constant";
import { getRequest, postRequest } from "../../Service/httpService.js";
import { useDispatch, useSelector } from "react-redux";
import { isLoading as setLoading } from "../../store/reducers/routeReducer.js";
import loader from "../../assets/icons/loader.gif";
import phoneGif from "../../assets/gifs/mobile.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showArtworkCheckout as setShow } from "../../store/reducers/routeReducer.js";

export const CheckoutArtwork = () => {
  const { selectedArtwork: artwork, showArtworkCheckout: show, userData:user } = useSelector(
    (state) => state.routeReducer
  );
  const [paymentUnderProcess, setPaymentUnderProcess] = useState(false);
  const [orderReference, setOrderReference] = useState(null);
  const handleClose = () => dispatch(setShow(false));
  const [stock, setStock] = useState(1);
  const [waitTime, setWaitTime] = useState(10); /// in seconds
  const [contactNumber, setContactNumber] = useState(null);
  const myRef = useRef(null);
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState();
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.MOBILE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const durationInSeconds = 120; // 2 minutes
  const API_INTERVAL = 5;

  const [errors, setErrors] = useState({});

  const validate = () => {
    setErrors({});

    if (!stock) {
      setErrors((errors) => ({ ...errors, stock: "Stock is required" }));
    }
    if (!address) {
      setErrors((errors) => ({ ...errors, address: "Address is required" }));
    }
    if (!contactNumber) {
      setErrors((errors) => ({
        ...errors,
        contactNumber: "Contact Number is required",
      }));
    }
    if (!province) {
      setErrors((errors) => ({ ...errors, province: "Province is required" }));
    }

    if (!stock || !address || !contactNumber || !province)
      return "missing fields.";
    if (contactNumber.length != 9)
      return "Contact Number must be 9 characters";
    return null;
  };

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(setShow(false));
        }
      }

      // Attach the event listener when the component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  const verifyPaymentStatus = () => {
    if (!orderReference) return;
    getRequest({
      url: `${CHECKOUT_URL.VERIFY_PAYMENT}?page=artwork&reference=${orderReference}`,
    })
      .then((res) => {
        if (res.data.verified) {
          setPaymentUnderProcess(false);
          dispatch(setShow(false));
          navigate(
            `/artwork?page=artwork&reference=${orderReference}`
          );
        }
      })
      .catch((err) => {
        dispatch(setShow(false));
        console.error(err.message);
      });
  };

  const checkoutUsingMobileBanking = (data) => {
    dispatch(setShow(false));
    dispatch(setLoading(true));

    postRequest({
      url: `${CHECKOUT_URL.ARTWORK}`,
      data,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.data.message);
        } else {
          setOrderReference(res.data.data.reference);
          setPaymentUnderProcess(true);
          dispatch(setShow(true));
        }

        setStock(1);
        setAddress("");
        setContactNumber(null);
        setProvince(null);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setLoading(false));
      });
  };

  const checkoutUsingCard = (data) => {
    dispatch(setLoading(true));
    dispatch(setShow(false));
    postRequest({
      url: CHECKOUT_URL.ARTWORK,
      data,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.data.message);
        } else {
          window.open(res.data.data.url, "_self");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setLoading(false));
      });
  };

  const Checkout = () => {

    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    const data = {
      artwork_id: artwork._id,
      address: address,
      city: city,
      state: province.value,
      zip: zip,
      stock: stock,
      contact_no: "243" + contactNumber,
      type: paymentMethod,
    };

    if (paymentMethod === PAYMENT_METHOD.CARD) checkoutUsingCard(data);
    else checkoutUsingMobileBanking(data);
  };

  // timer
  useEffect(() => {
    let interval;

    if (paymentUnderProcess) {
      interval = setInterval(() => {
        if (waitTime === 0) {
          setPaymentUnderProcess(false);
          dispatch(setShow(false));
          navigate(`/artwork?page=artwork&reference=${orderReference}`);
        }

        if (waitTime % API_INTERVAL === 0) {
          verifyPaymentStatus();
        }

        if (waitTime > 0) {
          setWaitTime(waitTime - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [paymentUnderProcess, waitTime]);

  // Reset the timer when processing becomes true
  useEffect(() => {
    if (paymentUnderProcess) {
      setWaitTime(durationInSeconds);
    }
  }, [paymentUnderProcess]);

  //   useOutsideClick(myRef, setShow);

  return paymentUnderProcess ? (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className="w-full flex justify-center items-center flex-col py-[5rem] px-10 gap-6">
          <img className="w-[50%]" src={phoneGif} alt="" />
          <div className="text-lg text-center">
            Your payment has been initiated. Unlocked
            your phone to validate the popup with your
            code.
          </div>
          <div className="text-lg text-center text-[#E15241]">
            Do not refresh the page
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className={styles["container"]} ref={myRef}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6 absolute right-4 top-4 cursor-pointer text-black"
            onClick={() => {
              dispatch(setShow(false));
              setContactNumber(null);
              setStock(null);
              setProvince(null);
              setAddress(null);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>

          <div className={styles["trip-details"]}>
            <div className={styles["header"]}>
              <h3 className="mb-2">Proceed with Checkout</h3>
            </div>
            <div className={styles["trip-details-container"]}>
              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Stock <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <span className={styles["ticket-price"]}>
                  <span>
                    <input
                      type="number"
                      placeholder={`${artwork?.stock} item available`}
                      value={stock}
                      onChange={(e) =>
                        setStock(
                          e.target.value
                            ? Math.min(Math.abs(e.target.value), artwork?.stock)
                            : null
                        )
                      }
                    />
                  </span>
                </span>

                {errors?.stock && (
                  <span className="text-xs text-red-500">{errors?.stock}</span>
                )}
              </div>
              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Full Address <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <input
                  className={styles["title"]}
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />

                {errors?.address && (
                  <span className="text-xs text-red-500">
                    {errors?.address}
                  </span>
                )}
              </div>

              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Province <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <Select
                  options={PROVINCES}
                  value={province}
                  onChange={(o) => setProvince(o)}
                />

                {errors?.province && (
                  <span className="text-xs text-red-500">
                    {errors?.province}
                  </span>
                )}
              </div>

              <div className=" bg-slate-100 p-4 rounded-lg ">
                <div className="flex items-center gap-x-2 text-gray-500">
                  <div className="text-sm font-semibold whitespace-nowrap">
                    Phone Number <span className={styles["mandatory"]}>*</span>{" "}
                  </div>
                  <div className="flex bg-white overflow-hidden rounded-xl">
                    <div className="rounded-xl  text-sm p-2  pr-0">+243</div>
                    <input
                      className="bg-inherit rounded-none text-sm p-2 pl-0 "
                      type="number"
                      placeholder="Enter Number"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </div>
                  <span className="text-[0.5rem]">
                    To be also used in case of Mobile Payment
                  </span>
                </div>

                {errors?.contactNumber && (
                  <span className="text-xs text-red-500">
                    {errors?.contactNumber}
                  </span>
                )}

                <div className="bg-slate-200 text-gray-500 font-semibold p-2 px-3 rounded-lg my-4 flex items-center justify-between">
                  <span>Total</span>
                  <span className="text-2xl">
                    ${new Intl.NumberFormat().format((user?.state == artwork.province ? artwork.price : artwork.priceDiffProvince) * stock)}
                  </span>
                </div>

                <div className="flex flex-col gap-y-2">
                  <div className="text-[0.7rem] text-gray-500 font-semibold whitespace-nowrap">
                    How do you want to pay ?
                  </div>

                  <div className="flex gap-x-2">
                    <span
                      onClick={() => {
                        setPaymentMethod(PAYMENT_METHOD.MOBILE);
                      }}
                      className={`px-4 py-1 ${paymentMethod == PAYMENT_METHOD.MOBILE
                        ? "bg-[#FF8600] text-white"
                        : "bg-white"
                        } rounded-full  text-[0.7rem] leading-none flex items-center cursor-pointer font-semibold`}
                    >
                      Mobile Money
                    </span>

                    <span
                      onClick={() => {
                        setPaymentMethod(PAYMENT_METHOD.CARD);
                      }}
                      className={`px-4 py-1 ${paymentMethod == PAYMENT_METHOD.CARD
                        ? "bg-[#FF8600] text-white"
                        : "bg-white"
                        } rounded-full  text-[0.7rem] leading-none flex items-center cursor-pointer font-semibold`}
                    >
                      Debit Card
                    </span>
                  </div>
                </div>

                <div className="flex justify-center items-center my-4">
                  <button
                    className="bg-[#5AC4F6] text-white font-semibold px-8 py-2 rounded-full"
                    onClick={(e) => {
                      Checkout(e);
                    }}
                  >
                    Checkout
                  </button>
                </div>

                <div className="text-gray-500  text-[0.6rem] flex flex-col">
                  <span>
                    Mobile Money: m-Pesa, Airtel Money, Orange Money, Africell
                    Money.
                  </span>
                  <span>Debit card: VISA, Master Card. </span>
                  <span>Couple prices applies from 2.</span>
                  <span>Child must be above 2 years old.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* OLD Comp */}
        {/* <div className={styles["container"]} ref={myRef}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6 absolute top-4 right-4 cursor-pointer"
            onClick={() => {
              dispatch(setShow(false));
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>

          <div className={styles["trip-details"]}>
            <div className={styles["header"]}>
              <h3>Fill out your adress details</h3>
            </div>
            <div className={styles["trip-details-container"]}>
              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Stock <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <span className={styles["ticket-price"]}>
                  <span>
                    <input
                      type="number"
                      value={stock}
                      onChange={(e) => setStock(Math.abs(e.target.value))}
                    />
                  </span>
                </span>

                {errors?.stock && (
                  <span className="text-xs text-red-500">{errors?.stock}</span>
                )}
              </div>
              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Full Address <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <input
                  className={styles["title"]}
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />

                {errors?.address && (
                  <span className="text-xs text-red-500">
                    {errors?.address}
                  </span>
                )}
              </div>

              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Contact Number <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <input
                  className={styles["title"]}
                  type="number"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(Math.abs(e.target.value))}
                />

                {errors?.contactNumber && (
                  <span className="text-xs text-red-500">
                    {errors?.contactNumber}
                  </span>
                )}
              </div>

              <div className={styles["item-100"]}>
                <div className={styles["label"]}>
                  Province <span className={styles["mandatory"]}>*</span>{" "}
                </div>
                <Select
                  options={PROVINCES}
                  value={province}
                  onChange={(o) => setProvince(o)}
                />

                {errors?.province && (
                  <span className="text-xs text-red-500">
                    {errors?.province}
                  </span>
                )}
              </div>

              <div className={styles["item-100"]}>
                <div className={styles["label"]}>How do you want to pay ?</div>
                <div className={styles["check-box-container"]}>
                  <div
                    className={styles["check-box"]}
                    onClick={() => {
                      setPaymentMethod(PAYMENT_METHOD.CARD);
                    }}
                  >
                    <span>
                      <img
                        src={
                          paymentMethod === PAYMENT_METHOD.CARD
                            ? checkGold
                            : uncheckGold
                        }
                        alt=""
                      />
                    </span>
                    <span
                      className={
                        styles[
                          paymentMethod === PAYMENT_METHOD.CARD
                            ? "text-active"
                            : "text-inactive"
                        ]
                      }
                    >
                      Debit Card
                    </span>
                  </div>
                  <div
                    className={styles["check-box"]}
                    onClick={() => {
                      setPaymentMethod(PAYMENT_METHOD.MOBILE);
                    }}
                  >
                    <span>
                      <img
                        src={
                          paymentMethod === PAYMENT_METHOD.MOBILE
                            ? checkGold
                            : uncheckGold
                        }
                        alt=""
                      />
                    </span>
                    <span
                      className={
                        styles[
                          paymentMethod === PAYMENT_METHOD.MOBILE
                            ? "text-active"
                            : "text-inactive"
                        ]
                      }
                    >
                      Mobile Banking
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles["create"]}>
                <button
                  onClick={(e) => {
                    Checkout(e);
                  }}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Modal>
    </>
  );
};
