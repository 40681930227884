import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { io } from "socket.io-client";

// StyleSheet
import "bootstrap/dist/css/bootstrap.min.css";
import "../App/index.css";
import "./fonts.css";

import NavBar from "./topNavBar";
import LeftSideBar from "./leftSideBar";
import axios from "axios";
import {
  isLogin as setLogin,
  cart as setCart,
  userData as setUserData,
  isLoading as setLoading,
  userData,
} from "../store/reducers/routeReducer";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, BACKEND_URL, SOCKET_SERVER_URL } from "../Config/constant";

import {
  AdminOnlyRoute,
  LoggedInUserOnlyRoute,
  ModeratorAndAdminRoute,
  ModeratorOnlyRoute,
  PartnerOnlyRoute,
} from "./ProtectedRoutes";
import Loader from "../Components/Loader/Loader";
import { scrollToTop } from "../helper";
import { getRequest } from "../Service/httpService";

import ChatBox from "../Components/ChatBox/ChatBox";
import { CheckoutArtwork } from "../Components/Checkout/Checkout";
import { CheckoutTrip } from "../Components/Checkout/CheckoutTrip";
import NotVerified from "../Components/NotVerified/NotVerified";
import LoginPopUpModal from "../Components/LoginPopUpModal";

// import LatestTrend from "../Components/LatestTrends";
// import ProfileUpdate from "../Components/ProfileUser/userProfile";
// import NewPasswordHandler from "../Components/PasswordReset/passwordReset";
// import ChangePassword from "../Components/ChangePassword";
// import UserPosts from "../Components/User Posts/UserPosts";
// import LoginPopUpModal from "../Components/LoginPopUpModal";
// import TripsAndPlaces from "../Components/TripsAndPlaces/TripsAndPlaces";
// import TripDetailed from "../Components/TripsAndPlaces/DetailedPage/Trip";
// import ArtPage from "../Components/Artworks/ArtPage";
// import LeadersInTrend from "../Components/RightSideComponent/LeadersInTrend/leadersInTrend";
// import CreateTrip from "../Elements/CreateTrip";
// import ChatBox from "../Components/ChatBox/ChatBox";
// import SinglePost from "../Components/LatestTrends/SinglePost";
// import AutoLogout from "./AutoLgout";
// import DetailedArtPage from "../Components/Artworks/DetaildArtPage";
// import Gallery from "../Components/Artworks/artGallery/Gallery";
// import OrderDetailsArt from "../Components/Artworks/orderDetails/OrderDetailsArt";
// import OrderDetailsTrip from "../Components/TripsAndPlaces/order details/OrderDetailsTrip";
// import PartnerDashboard from "../Components/Dashboard/Partner/PartnerDashboard";
// import About from "../Components/About/About";
// import TnC from "../Components/TnC/Tnc";
// import QRCodeScanner from "../Components/qrScanner/QRCodeScanner";
// import NotVerified from "../Components/NotVerified/NotVerified";
// import ModeratorDashboard from "../Components/Dashboard/Modarator/ModeratorDashboard";

const LatestTrend = lazy(() => import("../Components/LatestTrends"));
const ProfileUpdate = lazy(() =>
  import("../Components/ProfileUser/userProfile")
);
const NewPasswordHandler = lazy(() =>
  import("../Components/PasswordReset/passwordReset")
);
const ChangePassword = lazy(() => import("../Components/ChangePassword"));
const UserPosts = lazy(() => import("../Components/User Posts/UserPosts"));

const TripsAndPlaces = lazy(() =>
  import("../Components/TripsAndPlaces/TripsAndPlaces")
);
const TripDetailed = lazy(() =>
  import("../Components/TripsAndPlaces/DetailedPage/Trip")
);
const ArtPage = lazy(() => import("../Components/Artworks/ArtPage"));
const LeadersInTrend = lazy(() =>
  import("../Components/RightSideComponent/LeadersInTrend/leadersInTrend")
);
const CreateTrip = lazy(() => import("../Elements/CreateTrip"));
const SinglePost = lazy(() => import("../Components/LatestTrends/SinglePost"));
const AutoLogout = lazy(() => import("./AutoLgout"));
const DetailedArtPage = lazy(() =>
  import("../Components/Artworks/DetaildArtPage")
);
const Gallery = lazy(() => import("../Components/Artworks/artGallery/Gallery"));
const OrderDetailsArt = lazy(() =>
  import("../Components/Artworks/orderDetails/OrderDetailsArt")
);
const OrderDetailsTrip = lazy(() =>
  import("../Components/TripsAndPlaces/order details/OrderDetailsTrip")
);
const PartnerDashboard = lazy(() =>
  import("../Components/Dashboard/Partner/PartnerDashboard")
);
const About = lazy(() => import("../Components/About/About"));
const TnC = lazy(() => import("../Components/TnC/Tnc"));
const QRCodeScanner = lazy(() =>
  import("../Components/qrScanner/QRCodeScanner")
);

const ModeratorDashboard = lazy(() =>
  import("../Components/Dashboard/Modarator/ModeratorDashboard")
);

// import AdminDashboard from "../Components/Dashboard/Admin/AdminDashboard";
const AdminDashboard = lazy(() =>
  import("../Components/Dashboard/Admin/AdminDashboard")
);
// import Rightbar from "./Rightbar";
// import UploadArtwork from "../Elements/UploadArtwork";

const Rightbar = lazy(() => import("./Rightbar"));
const UploadArtwork = lazy(() => import("../Elements/UploadArtwork"));

// import Search from "../Components/Search/Search";
// import MyActivity from "../Components/MyOrders/MyActivity";
// import CreatePlace from "../Components/Places/CreatePlace";
// import Cart from "../Components/Cart/Cart";

const Search = lazy(() => import("../Components/Search/Search"));
const MyActivity = lazy(() => import("../Components/MyOrders/MyActivity"));
const CreatePlace = lazy(() => import("../Components/Places/CreatePlace"));
const Cart = lazy(() => import("../Components/Cart/Cart"));

// import PlaceDetails from "../Components/Places/PlaceDetails";
// import Profile from "../Components/Partners/Profile";
// import BecomePartner from "../Components/Partners/BecomePartner";
// import PaymentSuccessModal from "../Components/Modal/PaymentSuccess";
// import { CheckoutArtwork } from "../Components/Checkout/Checkout";
// import { CheckoutTrip } from "../Components/Checkout/CheckoutTrip";
// import BookingDetails from "../Components/TripsAndPlaces/BookingDetails";
// import AddPartnerForm from "../Components/Partners/AddPartnerForm";
// import PlaceReview from "../Components/Places/PlaceReview";
// import PartnerProfileReview from "../Components/Partners/PartnerProfileReview";
// import ArtworkReview from "../Components/Artworks/ArtworkReview";
// import SavedPage from "../Components/Saved/SavedPage";

const PlaceDetails = lazy(() => import("../Components/Places/PlaceDetails"));
const Profile = lazy(() => import("../Components/Partners/Profile"));
const BecomePartner = lazy(() =>
  import("../Components/Partners/BecomePartner")
);
const PaymentSuccessModal = lazy(() =>
  import("../Components/Modal/PaymentSuccess")
);

const BookingDetails = lazy(() =>
  import("../Components/TripsAndPlaces/BookingDetails")
);
const AddPartnerForm = lazy(() =>
  import("../Components/Partners/AddPartnerForm")
);
const PlaceReview = lazy(() => import("../Components/Places/PlaceReview"));
const PartnerProfileReview = lazy(() =>
  import("../Components/Partners/PartnerProfileReview")
);
const ArtworkReview = lazy(() =>
  import("../Components/Artworks/ArtworkReview")
);
const SavedPage = lazy(() => import("../Components/Saved/SavedPage"));

<link
  rel="stylesheet"
  href="https://unicons.iconscout.com/release/v4.0.0/css/line.css"
></link>;

const routeObject = [
  {
    path: "/",
    element: <LatestTrend />,
    rightSide: "",
  },
  {
    path: "/trends/:postId",
    element: <SinglePost />,
    rightSide: "",
  },
  {
    path: "/userposts/:username",
    element: <UserPosts />,
    rightSide: "",
  },
  {
    path: "/trips/create",
    element: <PartnerOnlyRoute Component={CreateTrip} />,
    rightSide: "",
  },
  {
    path: "/trips/create/:id",
    element: <PartnerOnlyRoute Component={CreateTrip} />,
    rightSide: "",
  },
  {
    path: "/places/create",
    element: <PartnerOnlyRoute Component={CreatePlace} />,
    rightSide: "",
  },
  {
    path: "/places/create/:id",
    element: <PartnerOnlyRoute Component={CreatePlace} />,
    rightSide: "",
  },
  {
    path: "/partner/add",
    element: <AddPartnerForm />,
    rightSide: "",
  },
  {
    path: "/places/:id",
    element: <PlaceDetails />,
    rightSide: "",
  },
  {
    path: "/place/review/:id",
    element: <ModeratorOnlyRoute Component={PlaceReview} />,
    rightSide: "",
  },
  {
    path: "/partner/review/:id",
    element: <ModeratorAndAdminRoute Component={PartnerProfileReview} />,
    rightSide: "",
  },
  {
    path: "/artwork/upload",
    element: <LoggedInUserOnlyRoute Component={UploadArtwork} />,
    rightSide: "",
  },
  {
    path: "/artwork/upload/:id",
    element: <LoggedInUserOnlyRoute Component={UploadArtwork} />,
    rightSide: "",
  },
  {
    path: "/partner/register",
    element: <AddPartnerForm />,
    rightSide: "",
  },
  {
    path: "/partner/profile/:id",
    element: <Profile />,
    rightSide: "",
  },
  {
    path: "/profilePage",
    element: <LoggedInUserOnlyRoute Component={ProfileUpdate} />,
    rightSide: "",
  },
  {
    path: "/forgetpassword",
    element: <NewPasswordHandler />,
    rightSide: "",
  },
  {
    path: "/changepassword",
    element: <LoggedInUserOnlyRoute Component={ChangePassword} />,
    rightSide: "",
  },
  {
    path: "/trips",
    element: <TripsAndPlaces />,
    rightSide: "",
  },
  {
    path: "/trips/:id",
    element: <TripDetailed />,
    rightSide: "",
  },
  {
    path: "/trips/booking/details/:id",
    element: <LoggedInUserOnlyRoute Component={OrderDetailsTrip} />,
    rightSide: "",
  },
  {
    path: "/artwork",
    element: <ArtPage />,
    rightSide: <LeadersInTrend />,
  },
  {
    path: "/artwork/order/details/:id",
    element: <LoggedInUserOnlyRoute Component={OrderDetailsArt} />,
    rightSide: <LeadersInTrend />,
  },
  {
    path: "/artwork/:id",
    element: <DetailedArtPage />,
    rightSide: "",
  },
  {
    path: "/artwork/review/:id",
    element: <PartnerOnlyRoute Component={ArtworkReview} />,
    rightSide: "",
  },
  {
    path: "/artwork/gallery/:id",
    element: <LoggedInUserOnlyRoute Component={Gallery} />,
    rightSide: "",
  },
  {
    path: "/my-activity",
    element: <LoggedInUserOnlyRoute Component={MyActivity} />,
    rightSide: "",
  },

  {
    path: "/about",
    element: <About />,
    rightSide: "",
  },
  {
    path: "/terms-and-conditions",
    element: <TnC />,
    rightSide: "",
  },
  {
    path: "/qr",
    element: <QRCodeScanner />,
    rightSide: "",
  },
  {
    path: "/admin",
    element: <AdminOnlyRoute Component={AdminDashboard} />,
    rightSide: "",
  },
  {
    path: "/partner",
    element: <PartnerDashboard />,
    rightSide: "",
  },
  {
    path: "/moderator",
    element: <ModeratorOnlyRoute Component={ModeratorDashboard} />,
    rightSide: "",
  },
  {
    path: "/search/:query",
    element: <Search />,
    rightSide: "",
  },
  {
    path: "/search",
    element: <Search />,
    rightSide: "",
  },
  {
    path: "/trips/bookings/qr/:id",
    element: <BookingDetails />,
    rightSide: "",
  },
  {
    path: "/cart",
    element: <LoggedInUserOnlyRoute Component={Cart} />,
    rightSide: "",
  },

  {
    path: "/saved",
    element: <LoggedInUserOnlyRoute Component={SavedPage} />,
    rightSide: "",
  },
];

export default function App() {
  const [lgShow, setLgShow] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const modal = queryParams.get("modal");
  const [selectedTab, setSelectedTab] = useState("Login-Tab");
  const [showNavOptions, setShowNavOptions] = useState(true);
  const dispatch = useDispatch();
  const socket = useRef(null);
  socket.current = io(SOCKET_SERVER_URL);
  const {
    isLogin,
    isLoading,
    userData: user,
  } = useSelector((state) => state.routeReducer);

  useEffect(() => {
    if (modal === "login") {
      setLgShow(true);
      setSelectedTab("Login-Tab");
    }
    if (modal === "signup") {
      setLgShow(true);
      setSelectedTab("SignUp-Tab");
    }
  }, [modal]);

  // !lgShow && !isLoading && setTimeout(() => {
  //   setLgShow(true);
  // }, 30000);

  // http://localhost:8080/auth/login/success

  useEffect(() => {
    const url = `${BACKEND_URL}/auth/login/success`;
    const getUser = () => {
      axios
        .get(url, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) return response.data.user;
          throw new Error("Authentication has failed!");
        })
        .then((user) => {
          dispatch(setUserData(user));
          dispatch(setLogin(true));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const reloadUser = () => {
      getRequest({ url: API_URL.GET_USER_DETAILS })
        .then((res) => {
          dispatch(userData(res.data.data));
        })
        .catch((err) => {
          console.error(err);
        });
    };

    isLogin ? reloadUser() : getUser();
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const getCartData = () => {
    getRequest({ url: API_URL.GET_CART })
      .then((res) => {
        dispatch(setCart(res.data.data));
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    getCartData();
  }, [user]);

  return (
    <>
      {isLoading && <Loader />}
      <BrowserRouter>
        <NavBar
          showNavOptions={showNavOptions}
          setShowNavOptions={setShowNavOptions}
        />
        <ChatBox socket={socket} />
        <CheckoutArtwork />
        <CheckoutTrip />
        <NotVerified />
        <LoginPopUpModal
          setLgShow={setLgShow}
          lgShow={lgShow && !isLoading}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />

        <Suspense fallback={<Loader />}>
          <main>
            <div className="container">
              <LeftSideBar
                showNavOptions={showNavOptions}
                setShowNavOptions={setShowNavOptions}
              />
              <Routes>
                {routeObject.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      element={<AutoLogout>{item.element} </AutoLogout>}
                    />
                  );
                })}
              </Routes>
              <Rightbar />
            </div>
          </main>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
