import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

export default function TopNavbarIcons({ isLogin }) {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="primary"
        className="signup-button"
        onClick={() => {
          navigate("?modal=signup");
        }}
      >
        Sign up
      </Button>

      <Button
      className="login-button"
        variant="primary"
        onClick={() => {
          navigate("?modal=login");
        }}
      >
        Login
      </Button>
    </>
  );
}
