import { combineReducers, configureStore } from "@reduxjs/toolkit";
import routeReducer from "./reducers/routeReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
const persistConfig = {
  key: "tripCongo",
  storage: storage,
};

const rootReducer = combineReducers({
  routeReducer: routeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
