import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import store from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const transition = cssTransition({
  enter: "custom-ease-in",
  exit: "custom-ease-out",
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <ToastContainer
        position="top-center"
        hideProgressBar
        className="bg-transparent"
        transition={transition}
        theme="colored"
        autoClose={3000}
        closeOnClick={false}
        rtl={false}
        pauseOnHover={false}
      />
    </PersistGate>
  </Provider>
);
reportWebVitals();
