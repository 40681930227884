import React, { useCallback, useEffect, useState } from "react";
import { API_URL, COUNTRY_CODE, PROVINCES } from "../Config/constant";
import { postRequest } from "../Service/httpService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import showPwdImg from "../assets/hide-password.svg";
import hidePwdImg from "../assets/show-password.svg";
import { toCapitalCase } from "../Shared Component/toCapitalCase";
import { useDispatch } from "react-redux";
import { isLogin, userData } from "../store/reducers/routeReducer";
import { Select } from "./CustomSelect.tsx";
import styles from "../Elements/uploadArtwork.module.css";
import { isLoading as setIsLoading } from "../store/reducers/routeReducer";

export default function SignUpComponent(props) {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [ReEnterUserPassword, setReEnterUserPassword] = useState("");
  const [showEmailError, setshowEmailError] = useState();
  const [showPasswordError, setshowPasswordError] = useState();
  const [showPasswordNotMatchError, setshowPasswordNotMatchError] = useState();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [showNameError, setshowNameError] = useState();
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [provinceError, setProvinceError] = useState(false);
  const dispatch = useDispatch();

  function signUpHandler(event) {
    event.preventDefault();

    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      userEmail
    );
    const isValidName = /^[A-Za-z\s]+$/.test(userName);
    const isValidPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#\$%\^&\*\(\)_\+=\[\]\{\}|\\:;'"<>,.?/])(?=.{8,})/.test(
        userPassword
      );

    if (!isValidEmail) {
      setshowEmailError(true);
      return;
    }
    if (!isValidName) {
      setshowNameError(true);
      return;
    }
    if (!isValidPassword) {
      setshowPasswordError(true);
      return;
    } else {
      setshowPasswordError(false);
    }

    if (userPassword !== ReEnterUserPassword) {
      setshowPasswordNotMatchError(true);
      return;
    } else {
      setshowPasswordNotMatchError(false);

      if (!state) {
        setProvinceError(true);
        return;
      } else {
        setProvinceError(false);
      }

      const data = {
        name: userName,
        state: state.value,
        email: userEmail,
        password: userPassword,
      };

      dispatch(setIsLoading(true));

      postRequest({
        url: API_URL.SIGN_UP,
        data,
      })
        .then((response) => {
          if (response.data.success) {
            toast.success(toCapitalCase("Account Created Successfully"));
            dispatch(userData(response.data.userData));
            dispatch(isLogin(true));
          } else {
            toast.error(toCapitalCase(response.data.message));
          }
        })
        .catch((e) => {
          toast.error(toCapitalCase("Email Already Exists"));
        });

      dispatch(setIsLoading(false));
    }
  }

  const emailHandler = (e) => {
    setshowEmailError(false);
    e.target.value[0] != " " && setUserEmail(e.target.value);
  };

  return (
    <Form
      onSubmit={signUpHandler}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row className="mb-3">
        <Form.Group as={Col} xs={6} controlId="formGridEmail">
          <Form.Control
            style={{
              border: "solid 2px #F5F5F5",
              borderLeft: "none",
              fontWeight: "500",
              fontSize: "15px",
            }}
            type={userEmail.length > 0 ? "email" : "text"}
            placeholder="Enter email"
            value={userEmail}
            onChange={emailHandler}
          />
          {showEmailError ? (
            <span
              style={{
                color: "red",
                fontSize: "12px",
              }}
              className="err"
            >
              *Enter valid email
            </span>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} xs={6} controlId="formGridName">
          <Form.Control
            style={{
              border: "solid 2px #F5F5F5",
              borderLeft: "none",
              fontWeight: "500",
              fontSize: "15px",
            }}
            type="text"
            value={userName}
            placeholder="User Name"
            onChange={(e) =>
              e.target.value[0] != " " && setUserName(e.target.value)
            }
          />
          {showNameError ? (
            <span
              style={{
                color: "red",
                fontSize: "12px",
              }}
              className="err"
            >
              *Enter valid Name
            </span>
          ) : null}
        </Form.Group>
      </Row>
      <Form.Group
        style={{
          position: "relative",
        }}
        className="mb-3"
        controlId="formGridPassword"
      >
        <Form.Control
          style={{
            border: "solid 2px #F5F5F5",
            borderLeft: "none",
            fontWeight: "500",
            fontSize: "15px",
          }}
          placeholder="Enter Password"
          type={isRevealPwd ? "text" : "password"}
          value={userPassword}
          onChange={(e) => {
            setshowPasswordError(false);
            e.target.value[0] != " " && setUserPassword(e.target.value);
          }}
        />
        <img
          style={{
            width: "20px",
            position: "absolute",
            right: "7px",
            top: "9px",
            cursor: "pointer",
          }}
          title={isRevealPwd ? "Hide password" : "Show password"}
          src={isRevealPwd ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwd((prevState) => !prevState)}
        />
        {showPasswordError ? (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              lineHeight: "17px",
              display: "block",
            }}
            className="err"
          >
            *Password must contain at least 1 lowercase, 1 uppercase, 1 numeric,
            1 special character & must be eight characters{" "}
          </span>
        ) : null}
      </Form.Group>
      <Form.Group
        className="mb-3"
        controlId="formGridReEnterPassword"
        style={{
          position: "relative",
        }}
      >
        <Form.Control
          style={{
            border: "solid 2px #F5F5F5",
            borderLeft: "none",
            fontWeight: "500",
            fontSize: "15px",
          }}
          placeholder="Re Enter Password"
          type={isRevealConfirmPwd ? "text" : "password"}
          value={ReEnterUserPassword}
          onChange={(e) => {
            setshowPasswordNotMatchError(false);
            e.target.value[0] != " " && setReEnterUserPassword(e.target.value);
          }}
        />
        <img
          style={{
            width: "20px",
            position: "absolute",
            right: "7px",
            top: "9px",
            cursor: "pointer",
          }}
          title={isRevealConfirmPwd ? "Hide password" : "Show password"}
          src={isRevealConfirmPwd ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealConfirmPwd((prevState) => !prevState)}
        />
        {showPasswordNotMatchError ? (
          <span
            style={{
              color: "red",
              fontSize: "12px",
              lineHeight: "17px",
              display: "block",
            }}
            className="err"
          >
            *Re-Enter Password Must Be Same as Change Password
          </span>
        ) : null}
      </Form.Group>

      <Row className="mb-3">
        <div className={styles["item-full"]} style={{ marginTop: 0 }}>
          <div className={styles["label"]}>
            Province <span className={styles["mandatory"]}>*</span>{" "}
          </div>
          <Select
            options={PROVINCES}
            value={state}
            onChange={(o) => setState(o)}
          />
        </div>
        {provinceError ? (
          <span
            style={{
              color: "red",
              fontSize: "12px",
            }}
            className="err"
          >
            *Province is required
          </span>
        ) : null}
      </Row>

      <Button
        className="submit"
        xs={6}
        variant="primary"
        type="submit"
        onClick={(e) => {
          signUpHandler(e);
        }}
      >
        Sign Up
      </Button>
    </Form>
  );
}
