import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";

import { getRequest, postRequest } from "../../Service/httpService";
import { API_URL } from "../../Config/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  chatModalState as setChatModalState,
  chatModalOpen as setChatModalOpen,
} from "../../store/reducers/routeReducer";
import { toast } from "react-toastify";
import ChatPage from "./ChatScreen/ChatPage";
import ConversationPage from "./ConversationsScreen/ConversationPage";

function MyVerticallyCenteredModal({ setUnreadChatCount, socket }) {
  const [conversations, setConversations] = useState([]);
  const dispatch = useDispatch();

  const { chatModalOpen, chatModalState, userData, chatPartner } = useSelector(
    (state) => state.routeReducer
  );

  // SOCKETS IMPLEMENTATION
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);


  function sendMessage() {
    if (newMessage.length < 1) {
      return;
    }

    const data = {
      senderId: userData?._id,
      receiverId: chatPartner?._id,
      text: newMessage,
    };

    postRequest({ url: API_URL.SEND_MESSAGE, data })
      .then((res) => {
        if (messages.length === undefined) {
          setMessages([res.data]);
        } else {
          setMessages([...messages, res.data]);
        }
        setNewMessage("");
        socket.current.emit("sendMessage", data); // SENDING MESSAGE TO SOCKET SERVER AFTER DATABASE
        getConversations();
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  useEffect(() => {
    try {
      socket.current.on("getMessage", (data) => {
        setArrivalMessage({
          sender: data.senderId,
          text: data.text,
          createdAt: Date.now(),
        });
      });
    } catch (err) {
      console.error("Error ", err);
    }
  }, []);

  useEffect(() => {
    userData && socket.current.emit("addUser", userData._id);
    socket.current.on("getUsers", (users) => {});
  }, [userData]);

  useEffect(() => {
    arrivalMessage &&
      ((userData && arrivalMessage.sender === userData._id) ||
        arrivalMessage.sender === chatPartner._id) &&
      chatModalState === "chat" &&
      getMessages();
  }, [arrivalMessage, chatPartner, userData]);

  useEffect(() => {
    setMessages([]);
  }, [chatPartner]);

  // SOCKETS IMPLEMENTATION

  function getMessages() {
    if (chatPartner?._id === userData?._id) {
      toast.error("You can not have a conversation with yourself!");
      dispatch(setChatModalState("conversation"));
    }
    getRequest({
      url: `${API_URL.GET_MESSAGES}${userData?._id}/${chatPartner?._id}`,
    })
      .then((res) => {
        setMessages(res.data);
        getConversations();
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  const getConversations = () => {
    getRequest({ url: `${API_URL.GET_CONVERSATIONS}` })
      .then((res) => {
        setConversations(res.data.data);
        const unreadChat = res.data.data.filter(
          (item) => item.unreadMessagesCount > 0
        );
        setUnreadChatCount(unreadChat.length);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    getConversations();
  }, [userData, arrivalMessage]);

  return (
    <Modal
      show={chatModalOpen}
      onHide={() => {
        dispatch(setChatModalOpen(false));
      }}
      closeModal={() => {
        dispatch(setChatModalOpen(false));
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {chatModalState === "chat" ? (
        <ChatPage
          closeModal={() => {
            dispatch(setChatModalOpen(false));
            dispatch(setChatModalState("conversation"));
          }}
          getConversations={getConversations}
          messages={messages}
          setMessages={setMessages}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessage={sendMessage}
          getMessages={getMessages}
        />
      ) : (
        <ConversationPage
          user={userData}
          conversations={conversations}
          closeModal={() => {
            dispatch(setChatModalOpen(false));
          }}
        />
      )}
    </Modal>
  );
}

const ChatBox = ({ socket }) => {
  const { chatModalOpen } = useSelector((state) => state.routeReducer);
  const [modalShow, setModalShow] = useState(true);
  const [unreadChatsCount, setUnreadChatCount] = useState(0);
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.routeReducer);
  const closeModal = () => {
    setModalShow(false);
  };

  return (
    isLogin && (
      <div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          closeModal={closeModal}
          setUnreadChatCount={setUnreadChatCount}
          socket={socket}
        />
      </div>
    )
  );
};

export default ChatBox;
