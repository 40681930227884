import TopNavbarIcons from "../Elements/loginModal";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  search as searchReducer,
  logout,
  middleBoxWidth,
  chatModalOpen,
} from "../store/reducers/routeReducer";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../Components/Notification/Notification";
import updatedLogo from "../assets/images/newLogo.svg";
// import updatedLogo from "../assets/images/newLogo.png";
// import updatedLogo from "../assets/images/updatedLogo.svg";
import defaultProfilePic from "../assets/images/default_profile_Pic.png";
import { API_URL, BACKEND_URL } from "../Config/constant";
import Searchbar from "./Search/Searchbar";
import cartIcon from "../assets/icons/cart-icon.svg";
import { getRequest } from "../Service/httpService";
function NavBar({ setShowNavOptions }) {
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setsearch] = useState("");
  const notificationRef = useRef(null);
  const [showRest, setShowRest] = useState(true);
  const [vw, setVw] = useState(0);

  const [notificationCount, setNotificationCount] = useState(0);

  const { userData, isLogin, isLoading, cart } = useSelector(
    (state) => state.routeReducer
  );
  const location = useLocation();
  const boxWidth = (className) => {
    const width =
      document.getElementsByClassName("trends-feed")[0]?.clientWidth;
    dispatch(middleBoxWidth(width));
  };

  React.useEffect(() => {
    let className = "trends-feed";
    if (location.pathname === "/trips") className = "place";
    boxWidth();

    window.addEventListener("resize", boxWidth);
  }, [location.pathname]);

  useEffect(() => {
    if (search === " ") setsearch("");
    search && search.length > 2 && dispatch(searchReducer(search));
  }, [search]);

  const handleResize = () => {
    setVw(window.innerWidth);
  };

  function getNotificationsCount() {
    getRequest({ url: API_URL.GET_NOTIFICATIONS + "count" })
      .then((res) => {
        setNotificationCount(res?.data?.data?.unseenCount);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  useEffect(() => {
    if (vw === 0) {
      handleResize();
    }

    getNotificationsCount();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());

    // window.open(`${BACKEND_URL}/auth/logout`, "_self");
  };

  const openChat = (e) => {
    e.preventDefault();
    dispatch(chatModalOpen(true));
  };

  return (
    <>
      <nav className="">
        {!isLoading && (
          <div className="nav-container">
            {showRest && (
              <div
                className="site-logo"
                onClick={(e) => {
                  navigate("/");
                }}
              >
                {vw < 992 && <div className="empty"></div>}
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={updatedLogo}
                  alt=""
                />
              </div>
            )}
            {isLogin ? (
              <Searchbar
                setShowRest={setShowRest}
                setShowNavOptions={setShowNavOptions}
              />
            ) : (
              <div></div>
            )}

            {showRest && (
              <div className="inner-wrap">
                {isLogin ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <div className="user-name">
                      <span className="greet">Hi, </span>
                      <span className="name">
                        {userData?.name?.split(" ")[0]}
                      </span>
                    </div>

                    {isLogin && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div className="parent-dropdown">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <div className="h-8 w-8 relative bg-gray-200 rounded-full ">
                                <img
                                  className="w-full h-full object-cover rounded-full"
                                  src={
                                    userData?.avatarImg
                                      ? userData?.avatarImg
                                      : defaultProfilePic
                                  }
                                  alt=""
                                />
                                {notificationCount && (
                                  <div className="p-1 absolute -top-1/2 -right-1/2 -translate-x-1/3 translate-y-1/2 aspect-square rounded-full bg-red-500">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-4 aspect-square"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="/profilePage">
                                Profile
                              </Dropdown.Item>
                              <Dropdown.Item
                                ref={notificationRef}
                                onClick={() => {
                                  setShowNotifications(true);
                                }}
                              >
                                Notifications{" "}
                                <span className="font-medium">
                                  {" "}
                                  {notificationCount
                                    ? `(${notificationCount})`
                                    : null}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  openChat(e);
                                }}
                              >
                                Chat
                              </Dropdown.Item>
                              <Dropdown.Item
                                href={`/artwork/gallery/${userData?._id}`}
                              >
                                Galleries
                              </Dropdown.Item>
                              <Dropdown.Item href={`/my-activity`}>
                                My Activity
                              </Dropdown.Item>
                              <Dropdown.Item href={`/saved`}>
                                Saved
                              </Dropdown.Item>
                              {/* <Dropdown.Item href="#/action-2">
                            Invite A Friend
                          </Dropdown.Item> */}
                              <Dropdown.Item
                                style={{
                                  color: "red",
                                }}
                                onClick={handleLogout}
                              >
                                Log Out
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <p>5</p> */}
                        </div>
                        <div
                          className="relative w-6 card-icon"
                          onClick={() => {
                            navigate("/cart");
                          }}
                        >
                          <img
                            className="w-full cursor-pointer"
                            src={cartIcon}
                            alt=""
                          />
                          {cart && cart.length > 0 && (
                            <p>{cart.length > 9 ? "9+" : cart.length}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {showNotifications && (
                      <Notification
                        showNotifications={showNotifications}
                        setShowNotifications={setShowNotifications}
                        setNotificationCount={setNotificationCount}
                      />
                    )}
                  </div>
                ) : (
                  <TopNavbarIcons
                    style={{
                      marginRight: "10px",
                    }}
                    // submitHandlerTopNav={submitHandlerTopNav}
                    // errorHandlerTopNav={errorHandlerTopNav}
                    isLogin={isLogin}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </nav>
    </>
  );
}

export default NavBar;
