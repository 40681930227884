import React, { useEffect, useRef, useState } from "react";
import imojiIcon from "../../../assets/icons/imoji-icon.svg";
import attachIcon from "../../../assets/icons/attachments-icon.svg";
import sendIcon from "../../../assets/icons/send-icon.svg";
import closeIcon from "../../../assets/icons/close-icon.svg";
import styles from "../chat-box.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  chatModalState as setChatModalState,
  chatPartner as setChatPartner,
} from "../../../store/reducers/routeReducer";

import { format } from "timeago.js";

import EmojiPicker from "emoji-picker-react";
import { dateFormatOne, formatDate } from "../../../helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ChatPage({
  closeModal,
  sendMessage,
  messages,
  newMessage,
  setNewMessage,
  getMessages,
}) {
  const dispatch = useDispatch();
  const { chatPartner, userData } = useSelector((state) => state.routeReducer);

  const [isEmojiBoxOpen, setIsEmojiBoxOpen] = useState(false);
  const myRef = useRef();

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      sendMessage();
    }
  }

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEmojiBoxOpen(false);
        }
      }

      // Attach the event listener when the component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  useOutsideClick(myRef, setIsEmojiBoxOpen);

  useEffect(() => {
    getMessages();
  }, [chatPartner, messages]);

  const scrollRef = useRef(null);

  // useEffect(() => {
  //   scrollRef.current?.scrollIntoView({ behaviour: "smooth" });
  // }, [messages]);

  const dateFormatter = (date) => {
    const ipDate = new Date(date);
    const currentDate = new Date();

    if (ipDate.toDateString() === currentDate.toDateString()) {
      return "Today";
    } else if (
      ipDate.toDateString() ===
      new Date(currentDate.getTime() - 86400000).toDateString()
    ) {
      return "Yesterday";
    } else {
      return formatDate(date);
      // return dateFormatOne(ipDate);
    }
  };

  return (
    <div className={styles["chat-window"]}>
      <div className={styles["modalHeader"]}>
        <div className="flex items-center gap-x-4 w-full">
          <svg
            onClick={() => {
              {
                dispatch(setChatModalState("conversations"));
                dispatch(setChatPartner(null));
              }
            }}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            stroke="#ffffff"
            className="w-6 h-6 cursor-pointer"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fill="#ffffff"
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
              ></path>
              <path
                fill="#ffffff"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
              ></path>
            </g>
          </svg>
          <div className="text-white font-semibold">{chatPartner?.name}</div>
        </div>
        <div className={styles["cur-pointer"]}>
          <img
            onClick={() => {
              closeModal();
            }}
            style={{ height: "15px", width: "15px" }}
            src={closeIcon}
            alt=""
          />
        </div>
      </div>
      <div className={styles["chat-section"]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: "10px",
            minHeight: "50vh",
          }}
        >
          {messages.length > 0 &&
            messages?.map((message, ind) => (
              <div key={ind} ref={scrollRef}>
                <div className="w-full  mb-2 flex justify-center text-xs text-textgray">
                  {dateFormatter(message?.date)}
                  {/* {new Date(message?.date).getDate()} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  {message?.messages?.map((message, ind) => (
                    <Message message={message} user={userData} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles["chat-options"]}>
        <div
          style={{
            position: "relative",
          }}
          className={styles["text-field"]}
        >
          {isEmojiBoxOpen && (
            <div
              ref={myRef}
              className="absolute top-0 z-40 -translate-y-full left-0"
            >
              <EmojiPicker
                onEmojiClick={(e) => {
                  setNewMessage((newMessage) => newMessage + e.emoji);
                }}
              />
            </div>
          )}
          <img
            onClick={() => {
              setIsEmojiBoxOpen(!isEmojiBoxOpen);
            }}
            className="cursor-pointer"
            src={imojiIcon}
          />
          <input
            placeholder="Type your message"
            value={newMessage}
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (newMessage == null || !newMessage?.trim()) {
                return;
              }

              handleKeyPress(e);
            }}
          />
        </div>
        <div className={styles["action-buttons"]}>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>
                Coming soon, for now use google drive links for sharing media
              </Tooltip>
            )}
            placement="top"
          >
            <img src={attachIcon} />
          </OverlayTrigger>

          <img
            src={sendIcon}
            onClick={(e) => {
              if (newMessage == null || !newMessage?.trim()) {
                return;
              }

              sendMessage(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Message({ message, user }) {
  const time = new Date(message?.createdAt);

  return message && message.sender === user?._id ? (
    <div className={styles["msg-sender"]}>
      <div className={styles["user-msg-time"]}>
        {/* <p>You, {format(message?.createdAt)}</p> */}
      </div>
      <div className="w-full px-4 flex justify-end">
        <div className="bg-[#D7E188] text-[#385C27] p-2 pb-1 rounded-lg rounded-tr-none relative">
          <div className=" relative z-20">
            <div>{message.text}</div>
            <div className="text-[0.5rem] text-textgray w-full text-right">
              {/* {time?.getHours() + ":" + parseInt(time?.getMinutes()) < 10
                ? "0" + time?.getMinutes()
                : time?.getMinutes()} */}
              {
                format(message.createdAt)
              }
            </div>
          </div>

          <div
            style={{
              borderLeft: "15px solid transparent",
              borderRight: "15px solid transparent",
              borderBottom: "15px solid #D7E188",
              borderTop: "15px solid transparent",
              display: "inline-block",
            }}
            className="w-6 absolute z-10 top-0 rotate-180 right-0 translate-x-1/2 aspect-square"
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles["msg-reciever"]}>
      <div className={styles["sender-msg-time"]}>
        {/* <p>Seller Name, {format(message.time)}</p> */}
      </div>

      <div className="w-full px-4 flex justify-start">
        <div className="bg-bgGray text-textgray p-2 pb-1 rounded-lg rounded-tl-none relative">
          <div className=" relative z-20">
            <div>{message.text}</div>
            <div className="text-[0.5rem] text-textgray w-full text-right">
              {/* {time?.getHours() + ":" + parseInt(time?.getMinutes()) < 10
                ? "0" + time?.getMinutes()
                : time?.getMinutes()} */}
              {format(message.createdAt)}
            </div>
          </div>

          <div
            style={{
              borderLeft: "15px solid transparent",
              borderRight: "15px solid transparent",
              borderBottom: "15px solid #F4F4F4",
              borderTop: "15px solid transparent",
              display: "inline-block",
            }}
            className="w-6 absolute z-10 top-0 rotate-180 left-0 -translate-x-1/2 aspect-square"
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
